import { Component, OnInit ,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { HttpService } from 'src/app/services/http/http.service';
@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.page.html',
  styleUrls: ['./search-list.page.scss'],
})
export class SearchListPage implements OnInit {

  public listBackup = [];
  public myInput ="";
  public clubArray = [];
  public centersList = [];
  public categories = [];
  public allData = [];
  public trainersFetch: boolean = false;
  public selectedPurpose = [];
  public multiArray = [];
  allSelected: boolean = false;
  selectedCategory: any= null;







  constructor(public translateService: TranslateService,private modalCtrl:ModalController, private storage: Storage,
              public HTPS: HttpService) {
                this.allSelected = true;

   }

  ngOnInit() {
                this.getData();
              
             }

  getData() {
    let clubBody = {
      'companyID': 999
    }
    this.storage.get('langSelect').then((vall) => {
    this.HTPS.withoutTokeninterCenterList(clubBody, vall, {}).subscribe((response) => {
            if(response.data && response.data['Tenant']) {
              this.allData =   response.data['Tenant'];
              this.trainersFetch = true; //DISPLAYING LIST ON UI ONLY IF THIS VARIABLE IS TRUE OTHERWISE SKELETON WILL BE DISPLAYED.
              this.categories = this.getAllCat(response.data['Tenant']);
              this.selectedPurpose = this.allData; //CREATING BACK-UP OF TRAINER LIST.
              this.centersList = response.data['Tenant'];
                this.listBackup = this.centersList;
                console.log(this.centersList,"center")
            }
      });
    });
  }

  ionViewWillEnter() {}

  returnResult(res)
  {
    this.modalCtrl.dismiss({"res":res});
  }

  onInput($event) {
    this.setFilteredItems(this.myInput)
  }

  onBackPress(event) {
    if (event.keyCode == 8 || event.keyCode == 46) {
      this.centersList = this.listBackup;
    }
  }

  setFilteredItems(term) {
    this.centersList = this.filterItemsSearch(term);
    console.log(this.centersList,"inside filter")
  }


    //MODIFIES THE LIST FROM THE SEARCH TEXT.
    filterItemsSearch(searchText:string) {
      if (!this.centersList) return [];
      if (!searchText) return this.listBackup;
      searchText = searchText.toLowerCase();
      console.log(searchText,"search")
      return this.listBackup.filter(it => {
        if (it.TenantName.toLowerCase())
          console.log(it.TenantName,"search")
          return it.TenantName.toLowerCase().includes(searchText);
      });
    }


      //CREATING NEW ARRAY HAVING ONLY CATEGORIES IN IT. [e.g. Swimming, PT, etc].
  getAllCat(arr) {
    console.log(arr,'aaa')
    let res = [];
    arr.map(m => { res.push( m.brandName); });
       return this.unique(res);
    }

  

  //Takes input array with duplicate values and returns array with unique values.
  unique(inp) {
    let uniqueValues = new Set(inp); //Using Set to get all unique values.
    let array = Array.from(uniqueValues);
    return array; //Array of unique values.
  }

   //SORTING BASED ON SELECTED CATEGORY.
   sortByPurpose(p, sv) {
    console.log(p,sv,"endpoint")
    if (sv == 'all') {
 
      this.allSelected = true;
      this.selectedCategory = -1
      this.centersList= this.listBackup;
    //  this.showAll = true;
    }
    else {
      this.allSelected = false;
     //  this.showAll = false;
     console.log(this.centersList,this.listBackup,"fff")
      this.centersList= this.listBackup;
      this.centersList = this.centersList.filter(obj => { 
        return obj.brandName == sv
      })
      console.log(  this.centersList,'cccc')
     // this.multiArray.push(this.selectedPurpose);
      this.selectedCategory = p;
    }



  
    }
  }

