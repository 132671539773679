import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
const LNG_KEY = 'SELECTED_LANGUAGE';
const DIR_KEY = "SELECTED_DIR";


@Injectable({
  providedIn: 'root'
})


export class LanguageService {
  selected = '';
  selectedDir;
  constructor(public http: HttpClient, private storage: Storage, private plt: Platform, private translate: TranslateService, ) {
    this.storage.get(DIR_KEY).then(dir => {
      if (dir)
      {
        this.selectedDir = dir;
      }
      else
      {
        this.selectedDir = 'ltr';
      }

    })


  }


  getlangg() {
    let promise = new Promise((resolve, reject) => {
      this.storage.get('langSelect').then((val) => {
        if (val == 'en-US' || val == 'ar-SA') {
          resolve(val)
        }
        else {
          (JSON.stringify(val)).includes('en-US') ? resolve('en-US') : resolve('ar-SA')
        }

      });
    });
    return promise;

  }



  setInitialAppLanguage() {
    //   let language = this.translate.getBrowserLang();
    //  this.translate.setDefaultLang(language);
    this.storage.get(LNG_KEY).then(val => {
      if (val) {
        this.storage.get(DIR_KEY).then(dir => {
          if (val == "ar-SA") {
            dir = 'rtl';
            this.storage.set(DIR_KEY, dir)
          }
          else
          {
            dir = 'ltr';
            this.storage.set(DIR_KEY, dir)
          }
          this.setLanguage(val, dir);
          this.selectedDir = dir;
          this.selected = val;
        });
      }
      else
      {
        this.setLanguage("en-US", 'ltr');
      }
    });
  }

  /*getLanguages() {
    return [
      { text: 'English', value: 'en' },
      { text: 'Arabic', value: 'ar' },
    ];
  }

  */
  getSelectedLang() {
    return this.selectedDir;
  }

  setLanguage(lng, dir) {
    console.log(lng, dir, 'set language')
    if (lng == 'ar-SA') {
      dir = 'rtl'
    }
    else if (lng == 'en-US') {
      dir = 'ltr'
    }
    document.documentElement.dir = dir;
    this.translate.use(lng);
    this.selected = lng;
    this.selectedDir = dir;
    this.storage.set(LNG_KEY, lng);
    this.storage.set(DIR_KEY, dir);
    this.storage.set('langSelect', lng);
  }

  //GETTING DATA FROM JSON.
  getLanguages() {
    return this.http.get('assets/Language.json');
  }
}
