import { Component, Input, ElementRef, Renderer,Output ,EventEmitter} from '@angular/core';

@Component({
  selector: 'shrinking-segment-header',
  templateUrl: 'shrinking-segment-header.html'
})
export class ShrinkingSegmentHeader {

  @Input('scrollArea') scrollArea: any;
  @Input('headerHeight') headerHeight: number;

  @Output() valueChange = new EventEmitter();
  Counter = 0;


  newHeaderHeight: any;

  constructor(public element: ElementRef, public renderer: Renderer) {

  }


 
  ngAfterViewInit(){

    this.renderer.setElementStyle(this.element.nativeElement, 'height', this.headerHeight + 'px');

    this.scrollArea.ionScroll.subscribe((ev) => {
      this.resizeHeader(ev);
    });

  }

  resizeHeader(ev){

    ev.domWrite(() => {

      this.newHeaderHeight = this.headerHeight - ev.scrollTop;

      if(this.newHeaderHeight < 0){
        this.newHeaderHeight = 0;
      }   let pass=1;

      if( this.newHeaderHeight == 0)
      {
if(pass==1){
  pass=0;
  this.valueChange.emit(this.newHeaderHeight );
  
  
}      
      }else{

        this.valueChange.emit(this.newHeaderHeight );
        pass=1;
      }






     // this.renderer.setElementStyle(this.element.nativeElement, 'height', this.newHeaderHeight + 'px');

      /*for(let headerElement of this.element.nativeElement.children){

        let totalHeight = headerElement.offsetTop + headerElement.clientHeight;

        if(totalHeight > this.newHeaderHeight && !headerElement.isHidden){
          headerElement.isHidden = true;
          this.renderer.setElementStyle(headerElement, 'opacity', '0');
        } else if (totalHeight <= this.newHeaderHeight && headerElement.isHidden) {
          headerElement.isHidden = false;
          this.renderer.setElementStyle(headerElement, 'opacity', '0.7');
        }

      }*/



      this.renderer.setElementStyle(this.element.nativeElement, 'height', this.newHeaderHeight + 'px');

    });

  }

}