import { modalController } from '@ionic/core';
import { Route } from '@angular/compiler/src/core';
import { async } from '@angular/core/testing';
import { from, Subscription } from 'rxjs';
import { Component, Input, OnInit, wtfLeave } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ModalController, NavParams, LoadingController, ToastController,AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
 import { EligibleLocationModalPage } from '../eligible-location-modal/eligible-location-modal.page';
import { Storage } from '@ionic/storage';
import { HttpService } from 'src/app/services/http/http.service';
import { CalendarComponentOptions } from 'ion2-calendar';
import { CartServiceService } from '../../services/cart-service/cart-service.service';
import { DataServiceService } from 'src/app/services/data-service/data-service.service';
import { LanguageService } from '../../services/language/language.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import{AppRatingService}from 'src/app/services/app-rating/app-rating.service'


@Component({
  selector: 'app-freeze',
  templateUrl: './freeze.page.html',
  styleUrls: ['./freeze.page.scss'],
})
export class FreezePage implements OnInit {
  public membership;
  public productStartDate;
  public productEndDate;
  public MaxFreezeDays;
  public FreezeAttempt;
  public FreezeType;
  public MembershipStatus;
  noToDateOnly: boolean = false;
  showFromDate: any
  showToDate: any

  private subscription: Subscription;
  public dismissbtn;
  public dateselect;
  public startdate;
  public startandEnd;
  public endDateSelect;
  public endDate;
  public isoCode;
  allSelected: boolean
  startDateClicked : boolean=false;
  endDateClicked : boolean= false;


  public cartmsg;
  public previouscartitems;
  public alertButton1Offer;

  public CurrentCurrency;
  public loading;
  public MinimumDays;
  public MinimumRate;
  public Totalrate;
  public isdisabled: boolean = true;
  freezeoverlap: any;
  nothingSelected: boolean
  public isLoading: boolean;
  public setofferLocally = [];
  date: any;
  fromDate: any;
  toDate: any;
  public optionsRangeEn: CalendarComponentOptions;
  public optionsRangeAr: CalendarComponentOptions;

  @Input() membershipFreezeObj: any;
  // optionsRangeEn: CalendarComponentOptions = {
  //   pickMode: 'range',
  //   from: new Date(),
   
  // };
  // optionsRangeAr: CalendarComponentOptions = {
  //   pickMode: 'range',
  //   weekdays: ['سبت','جمعة','خميس','أربعاء','ثلاثاء','إثنين','أحد'],
  //   monthPickerFormat: ['يناير‎','فبراير‎','مارس‎','أبريل‎','مايو ','يونيو‎','يوليو‎','أغسطس‎','سبتمبر‎','أكتوبر‎','نوفمبر‎','ديسمبر‎'], 
  //   from:new Date(),
  // };
  themeName: any;


  constructor(private router: Router, public theme: ThemeService,
    public toastController: ToastController,
    public alertCtrl: AlertController,
    public modalController: ModalController,
    private _cartService: CartServiceService,
    private platform: Platform,
    public languageService: LanguageService,
    public navParams: NavParams,
    public  apprating:AppRatingService,
    public translateService: TranslateService,
    private dataService: DataServiceService,
    private loadingCtrl: LoadingController,
    private storage: Storage,
    private HTTPS: HttpService
  ) { }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter()
  {

    this.membershipFreezeObj['MaxFreezeDays']=this.membership.FreezeType == 'specialfreeze' ?this.membershipFreezeObj['SpecialFreezeDays']:this.membershipFreezeObj['MaxFreezeDays'];    
    let specialExpire = moment().add(this.membershipFreezeObj.SpecialFreezeDays,'days')
    let exdate = this.membership.FreezeType == 'specialfreeze' ? specialExpire.toDate(): new Date(this.membershipFreezeObj.ExpiryDate);

   //  this.membership.FreezeType == 'specialfreeze'
   // let finalDate =  moment(this.membershipFreezeObj.ExpiryDate).add(this.membershipFreezeObj.MaxFreezeDays,"days");
   // finalDate.toDate() Replace tis by  exdate for the max freeze Days.
    //console.log('finalDateeee',finalDate)
    
    this.optionsRangeEn = {
      pickMode: 'range',
      from: new Date(),
      to: exdate
     
    };
    this.optionsRangeAr = {
      pickMode: 'range',
      weekdays: ['سبت','جمعة','خميس','أربعاء','ثلاثاء','إثنين','أحد'].reverse(),
      monthPickerFormat: ['يناير‎','فبراير‎','مارس‎','أبريل‎','مايو ','يونيو‎','يوليو‎','أغسطس‎','سبتمبر‎','أكتوبر‎','نوفمبر‎','ديسمبر‎'], 
      from:new Date(),
      to:exdate
    };

    // this.optionsRangeEn = {
    //   pickMode: 'range',
    //   from: new Date(),
    //   to:(moment(this.membershipFreezeObj.ExpiryDate,"DD MMM YY").toDate()||new Date())
    // };

    // this.optionsRangeAr = {
    //   pickMode: 'range',
    //   weekdays: ['سبت','جمعة','خميس','أربعاء','ثلاثاء','إثنين','أحد'],
    //   monthPickerFormat: ['يناير‎','فبراير‎','مارس‎','أبريل‎','مايو ','يونيو‎','يوليو‎','أغسطس‎','سبتمبر‎','أكتوبر‎','نوفمبر‎','ديسمبر‎'], 
    //   from:new Date(),
    //   to:(moment(this.membershipFreezeObj.ExpiryDate,"DD MMM YY").toDate()||new Date() )
    // };
  }



  ngOnInit() {
   
    setTimeout(() => {
      this.theme.storedThemetype.then(theme => {
        console.log(theme)
        this.themeName = theme
      });
    }, 2000)
    this.languageService.getlangg().then(v => this.isoCode = v);
    this.membership = this.membershipFreezeObj;
    this.membership["Currency"] = this.membership.currency;
    this.storage.get('appCurrency').then(val => {
      if (val) {
        this.CurrentCurrency = val.currencyFormat;
      }
    });

    this.subscription = this._cartService.CartState.subscribe((state: CartState) => {
      //  this.products = state.products;
    });
    this._cartService.refreshCart()
  }

  //   onChange(event){
  //   console.log( 'here')
  //   this.fromDate = moment(event.from).format('DD-MM-YYYY');
  //   this.toDate = moment(event.to).format('DD-MM-YYYY');
  // if((this.fromDate!="" || this.fromDate!=null || this.fromDate!=undefined))
  // {
  //   this.noToDateOnly = true
  // }
  // else
  // {
  //   this.noToDateOnly=false
  // }
  //   }


  DateRange(event) {
    console.log(event)
    this.fromDate = moment(event.from).format('DD-MM-YYYY');
    this.toDate = moment(event.to).format('DD-MM-YYYY');
    var startDate = moment(this.fromDate, "DD.MM.YYYY");
    var endDate = moment(this.toDate, "DD.MM.YYYY");
    var days = endDate.diff(startDate, 'days');
    if (this.fromDate != undefined && this.toDate != undefined) {
      this.allSelected = true
      this.showFromDate = this.fromDate;
      this.showToDate = this.toDate;
      this.noToDateOnly = true;
    }
    else {
      this.allSelected = false
    }
    if (days <= this.membership.MaxFreezeDays) {
      this.isdisabled = false;
      //return this.CalculateFreezeRateAsPerCycle(this.fromDate, this.toDate)
    }
    else {
      this.isdisabled = true;
      let maxfreezedayreached
      this.translateService.get('MAXFREEZEDAYSREACHED').subscribe(value => {
        maxfreezedayreached = value;
        let msg = maxfreezedayreached;
        this.translateService.get('FREEZRANGE').subscribe(value => {
         let msg2 = value;
        this.presentAlert(this.membership.FreezeType == 'specialfreeze' ?msg2:msg);
      })  })
    }
  }

  selectStartDate(event)
  {
    if(event)
    {
      if(this.startDateClicked)
      {
        this.startdate=undefined;
        this.endDate=undefined;
        this.toDate= undefined;
        this.date="";
        this.isdisabled = true;
        this.allSelected=false;
        this.startDateClicked= false;
        this.noToDateOnly=false;
      }else
      {
        this.startDateClicked = true
      }
      
    }
    
  }

  selectEndDate(event)
  {
    if(event)
    {
      if(this.endDateClicked)
      {
        this.startdate=undefined;
        this.endDate=undefined;
        this.toDate= undefined;
        this.date="";
        this.endDateClicked= false;
        this.allSelected=false;
        this.noToDateOnly=false;
        this.isdisabled = true;
      }
      else
      {
        this.endDateClicked = true
      }
     
    }
  }
  

  async  EligibleLocations(Data) {
    console.log(Data, "locaTion")
    let Eligible = await this.modalController.create({
      component: EligibleLocationModalPage,
      componentProps: { "eligibleLocationMebershipIds": Data },
      cssClass: 'eligible-location-css',
    });
    return await Eligible.present();
  }

  backtoMyMembership() {
    this.modalController.dismiss();
  }
  async presentLoading() {
    let waitText
    this.translateService.get('PLEASEWAIT').subscribe(value => {
      waitText = value;
    })
    this.loading = await this.loadingCtrl.create({
      duration: 1000,
      message: waitText,
    });
    return await this.loading.present();
  }

  // freeze overlap alert
  async presentAlert(message: string) {
    let okText
    this.translateService.get('OK').subscribe(value => {
      okText = value
    })
    let overlapAlert = await this.alertCtrl.create({
      header: this.freezeoverlap,
      message: message,
      buttons: [
        {
          text: okText,
          handler: () => {
            this.alertCtrl.dismiss();
            this.startdate = "";
            this.toDate = "";
            this.modalController.dismiss();
          }
        }]
    });
    //this.loading.dismiss();
    await overlapAlert.present();
  }
  async selectEndDateAlert(message: string) {
    let okText
    this.translateService.get('OK').subscribe(value => {
      okText = value
    })

    let alertText
    this.translateService.get('ALERT').subscribe(value => {
      alertText = value
    })
    let selectEndDate = await this.alertCtrl.create({
      header: alertText,
      message: message,
      buttons: [
        {
          text: okText,
          handler: () => {
            
          }
        }]
    });
    //this.loading.dismiss();
    await selectEndDate.present();
  }

  caclRate() {
    if (this.fromDate && this.toDate) {
      return this.CalculateFreezeRateAsPerCycle(this.fromDate, this.toDate)
    } else {
      return '';
    }
  }

  freezeBillingOverlap() {
    this.membership.FreezeType == 'specialfreeze'?this.SpecialFreeze():this.CurrentFreeze();
  
  }
  CurrentFreeze(){
    this.presentLoading();
    this.Totalrate = this.CalculateFreezeRateAsPerCycle(this.fromDate, this.toDate);
    let freezeBillingobj = {
      "membershipID": this.membership.membershipID,
      "centerID": this.membership.BilledForID,
      "comments": "Freeze from Mobile App",
      "fromDate": this.fromDate,
      "toDate": this.toDate,
      "additionalPaymentID": this.membership.FreezeAdditionalPaymentID,
      "activationDate": moment(this.membership.StartDate).format('DD-MM-YYYY'),
      "payment": [],
      "trackMode": "mobileapp"
    }
    this.storage.get('tocken').then(val => {
      let tocken = val;
      console.log("Langggggg :", this.isoCode)
      this.HTTPS.freezeBillingOverlapPost(freezeBillingobj, tocken, this.isoCode)
        .subscribe(response => {
          console.log(response, 'first call')
          if (response.status == 200) {
            // this.loading.dismiss();
            this.goToCart();
          }
          else {
            this.presentAlert(response.data.message);
          }
        })

    });
  }

  SpecialFreeze(){

    let msg1;
    this.translateService.get('STARTFREEZ').subscribe(value => {
      msg1 = value;
    })

    let msg2;
    this.translateService.get('FREEZRANGE').subscribe(value => {
      msg2 = value;
    })

   
   


    if(!moment().isSame(moment(this.fromDate, 'DD-MM-YYYY'),'days')){
    if(!moment( this.fromDate, 'DD-MM-YYYY').add(Number(this.membershipFreezeObj['SpecialFreezeDays']), "days").isSameOrAfter(moment(this.toDate, 'DD-MM-YYYY'),'days'))
  {
    this.selectEndDateAlert(msg1+'<br>'+ msg2);


    }else{
      this.selectEndDateAlert(msg1);

    }

    }else{

    if(!moment( this.fromDate, 'DD-MM-YYYY').add(Number(this.membershipFreezeObj['SpecialFreezeDays']), "days").isSameOrAfter(moment(this.toDate, 'DD-MM-YYYY'),'days')){
      this.selectEndDateAlert( msg2);

    }
    else{
      this.CurrentFreeze();
    }
    }

  }

  CalculateFreezeRateAsPerCycle(from, to) {
    // let todate = moment(to, 'DD MMM YYYY hh:mm A');
    // let fromdate = moment(from, 'DD MMM YYYY hh:mm A');

    // let DurationTotalDays = todate.diff(fromdate, 'days') + 1;
    // let cycles;
    // if (DurationTotalDays < this.membership.MaxFreezeDays) {
    //   cycles = 1;
    // } else {
    //   cycles = DurationTotalDays / this.membership.MaxFreezeDays;
    //   Math.ceil(cycles)
    // }
    // let cycle = cycles.toFixed(0)
    //let TotalCost = Number(cycle) * this.membership.FreezeFee_PerAttempt;
    let TotalCost = Number(this.membership.FreezeFee_PerAttempt);
    return TotalCost;
  }

  goToCart() {
    console.log(this.toDate, this.fromDate, 'gotcart')
        let todate = moment(this.toDate, 'DD-MM-YYYY');
        let fromdate = moment(this.fromDate, 'DD-MM-YYYY');
        let totDays = Math.abs(Number((todate.diff(fromdate, 'days') + 1))) 
      if (this.toDate && this.fromDate) {
      let freeze = {
        'freezefromDate': this.fromDate,
        'freezetoDate': this.toDate,
        'freezedurationinDays': totDays
      }
      let obj = {
        "quantity": 1,
        "amount": Number(this.Totalrate),
        "cartObject": freeze,
        "tax": null,
        "type": "freeze",
        "DateToStart": null,
        "offerDetails": null,
        "membership": this.membership,
      };
      this.setofferLocally.push(obj);
    }

    if (this.membership.FreezeType == 'prepaid' || this.membership.FreezeType.includes("مسبق الدفع") || this.membership.FreezeType == 'specialfreeze' ) {
      this.PrepaidFreezeMembership();
    } else {
      let todate = moment(this.toDate, 'DD MMM YYYY hh:mm A');
      let fromdate = moment(this.fromDate, 'DD MMM YYYY hh:mm A');
      let freeze = {
        'freezefromDate': this.fromDate,
        'freezetoDate': this.toDate,
        'freezedurationinDays': totDays
      }

      let obj = {
        "quantity": 1,
        "amount": Number(this.Totalrate),
        "cartObject": freeze,
        "tax": null,
        "type": "freeze",
        "DateToStart": null,
        "offerDetails": null,
        "membership": this.membership,
      };

      this._cartService.emptyCart();

      this._cartService.addProduct(obj);

      if (this.platform.is('mobileweb') || this.platform.is('desktop')) 
      {
        this._cartService.setAllProducts();
      }

      this.backtoMyMembership();
      let navigateData = {
        cart: true,
        type: "freeze"
      }
      this.dataService.setData('type', navigateData);
      this.router.navigateByUrl('/buy-membership/type');

    }
  }


  PrepaidFreezeMembership() {
    console.log('inside prepaid')
    let prepaidPostObj: any;
    prepaidPostObj =
      {
        "isspecial_freeze_enabled":this.membership['FreezeType']=='specialfreeze'?true:false,
        "membershipID": this.membership.membershipID,
        "centerID": this.membership.BilledForID,
        "comments": "Freeze from Mobile App",
        "fromDate": this.fromDate,
        "toDate": this.toDate,
        "additionalPaymentID": this.membership.FreezeAdditionalPaymentID,
        "payment": [],
        'billOwner': null,
        'isoCode': this.isoCode
      }
    this.storage.get('tocken').then((val) => {
      let tocken = val;
      this.HTTPS.Freeze(prepaidPostObj, tocken, this.isoCode)
        .subscribe((response) => {
          console.log(response, 'second call')
          if (response) {
            this.loading.dismiss();
            if (Number(response.status) == 200) {
              if (response.data.status) {
                this.translateService.get('FreezeSUCCESS').subscribe(value=>{
                  this.presentToastWithOptions(value);
                });

                this.modalController.dismiss();
                setTimeout(() => {
                  this.apprating.getrating();
                  }, 500)
              }
            }
            if (Number(response.status) == 504) {
              // Fail
              this.translateService.get('FreezeUNSUCCESS').subscribe(value=>{
                this.presentToastWithOptions(value);
              });
              console.log(response);
            }
            if (Number(response.status) == 500) {
              console.log(response);
              this.translateService.get('FreezeUNSUCCESS').subscribe(value=>{
                this.presentToastWithOptions(value);
              });
            }
          }
        },
          error => {
            console.log(error);
          })
    });

  }

  // showAllert() {
  //   if (this.isdisabled) {
  //     if (this.fromDate == undefined) {
  //       if (this.toDate == undefined) {
  //         this.translateService.get('STARTANDEND').subscribe(
  //           value => {
  //             this.startandEnd = value;
  //             this.presentAlert(this.startandEnd)

  //           });
  //       }
  //     }
  //     if (this.fromDate!=undefined && this.toDate == undefined) {
  //       this.selectEndDateAlert("Please select end date")
  //     }
  //   }
  // }

  async presentToastWithOptions(ToastMessage) {
    const toast = await this.toastController.create({
      message: ToastMessage,
      position: 'bottom',
      duration: 3000
    });
    toast.present();
  }

  showAllert()
  {
    if(this.isdisabled)
    {
      console.log("dates",this.fromDate,this.toDate)
      if(!this.startDateClicked && !this.endDateClicked)
      {
        this.translateService.get('STARTANDEND').subscribe(
                    value => {
                      this.startandEnd = value;
                      this.selectEndDateAlert(this.startandEnd)
        
                    });
      }
      else if(this.startDateClicked && !this.endDateClicked)
      {
        let pleaseSelectEndDateText
        this.translateService.get('SELECTENDDATE').subscribe(
          value => {
            pleaseSelectEndDateText = value
            this.selectEndDateAlert(pleaseSelectEndDateText)

          });
        
      }
    }
  }

}

interface CartState {
  added: boolean;
  products: any[];

}