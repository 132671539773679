import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { APPversion } from './config';
import { LogoutService } from './logout.service';
import { Device } from '@ionic-native/device/ngx';
import { HttpService } from './services/http/http.service';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateAppService {

  public StorageLoaded;
  isAlertOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  stortype: boolean;


  constructor(
    public translateService: TranslateService,
    private alertCtrl: AlertController,
    private platform: Platform,
    private logout: LogoutService,
    public toastController: ToastController,
    public alertController: AlertController,
    public device: Device,
    private HTPS: HttpService,
    private storage: Storage
  ) {

    // events.subscribe('updatepopup', () => {
    //   this.Deviceforupdate();
    // });
  }





  // async UpdateAPP(response) {
  //   let UpdateNow;
  //   let Later;
  //   let UpdateHeader;
  //   let UpdateContent;
  //   console.log('LOGS FOUND')
  //   this.translateService.get('UPDATEHEADER').subscribe(
  //     value => {
  //       UpdateHeader = value;

  //     });
  //   this.translateService.get('UPDATECONTENT').subscribe(
  //     value => {
  //       UpdateContent = value;
  //     });
  //   this.translateService.get('UPDATENOW').subscribe(
  //     value => {
  //       UpdateNow = value;
  //     });
  //   this.translateService.get('LATER').subscribe(
  //     value => {
  //       Later = value;
  //     });




  //   let alert = await this.alertCtrl.create({
  //     message: UpdateHeader,
  //     subHeader: UpdateContent,
  //     buttons: [
  //       {
  //         text: UpdateNow,
  //         handler: () => {
  //           if (this.platform.is('android')) {
  //             window.open("https://play.google.com/store/apps/details?id=leejam.fitnesstime&hl=en", "_system");
  //           }
  //           if (this.platform.is('ios')) {
  //             // iOS
  //             window.open("itms-apps://itunes.apple.com/br/app/fitnesstime-app/id1455950113?mt=8", "_system");
  //           }
  //         }
  //       },
  //       {
  //         text: Later,
  //         handler: () => {
  //           this.alertCtrl.dismiss()
  //         }
  //       }
  //     ]
  //   });


  //   if (response.data.androidVersion || response.data.iOSVersion) {

  //     if (this.platform.is('android')) {
  //       if (this.compareVersions(APPversion, response.data.androidVersion)) {
  //         await alert.present();
  //       }
  //     }
  //     else
  //       if (this.platform.is('ios')) {
  //         if (this.compareVersions(APPversion, response.data.iOSVersion)) {
  //           await alert.present();
  //         }
  //       }

  //   }
  // }

  backButtonSubscription;
  ionViewWillEnter() {
    this.backButtonSubscription = this.platform.backButton.subscribe(async () => {
      navigator['app'].exitApp();
    });
  }

  ionViewDidLeave() {
    this.backButtonSubscription.unsubscribe();
  }

  async updateApp() {
    let appDetails = {
      'currentVersion': APPversion,
      'deviceType': this.device.platform ? this.device.platform :'android',
      'clientId': "B2C"
    }
    this.HTPS.Updatedivices(appDetails)
      .subscribe((response) => {
        if (response) {
          if (response['data'].updateType == 'Forceful') {
            this.forceUpdateAlert();
          }
          else if (response['data'].updateType == 'Manual') {
            this.manualUpdateAlert();
          }
          else if (response['data'].updateType == "") {
            return;
          }
        }

      })


  }


  async forceUpdateAlert() {

    let UpdateNow;
    let Later;
    let UpdateHeader;
    let UpdateContent;
    let upademasg;
    console.log('LOGS FOUND')
    this.translateService.get('UPDATEAVAILABLE').subscribe(
      value => {
        UpdateHeader = value;
      });
    this.translateService.get('UPDATECONTENT').subscribe(
      value => {
        UpdateContent = value;
      });
    this.translateService.get('UPDATE NOW').subscribe(
      value => {
        UpdateNow = value;
      });
    this.translateService.get('close').subscribe(
      value => {
        Later = value;
      });
      this.translateService.get('upademasg').subscribe(
        value => {
          upademasg = value;
        });


    const alert = await this.alertController.create({
      header: UpdateHeader,
      message: upademasg,
      buttons: [
        {
          text: UpdateNow,
          // cssClass: 'secondary',
          handler: () => {
            alert.dismiss();
            this.gotoappstore();
          }
        }, {
          text: Later,
          handler: () => {
            this.isAlertOpen.next(false);
            navigator['app'].exitApp();
          }
        }
      ],
      backdropDismiss: false,
    }
    );

    await alert.present();
  }
 
  async manualUpdateAlert() {
    let UpdateNow;
    let Later;
    let UpdateHeader;
    let UpdateContent;
    let updatemage;
    console.log('LOGS FOUND')
    this.translateService.get('Update available').subscribe(
      value => {
        UpdateHeader = value;

      });
    this.translateService.get('UPDATECONTENT').subscribe(
      value => {
        UpdateContent = value;
      });
    this.translateService.get('UPDATENOW').subscribe(
      value => {
        UpdateNow = value;
      });
    this.translateService.get('LATER').subscribe(
      value => {
        Later = value;
      });
      this.translateService.get('updatemage').subscribe(
        value => {
          updatemage = value;
        });


    const alert = await this.alertController.create({

      // cssClass: 'my-custom-class',
      header: UpdateHeader,
      message:updatemage ,
      buttons: [
        {
          text: UpdateNow,
          // cssClass: 'secondary',
          handler: () => this.gotoappstore()

        }, {
          text: Later,
          handler: () => {
          this.storage.set('isManualUpAlertClosed', true).then(val => console.log(val));
            document.addEventListener("backbutton", function (e) {
              console.log("disable back button")
            }, false);
            this.backButtonSubscription.unsubscribe();

          }
        }
      ],
      backdropDismiss: false
    }
    );

    await alert.present();

  }

  gotoappstore() {
    if (this.platform.is('android')) {
      if (this.device.manufacturer.toLowerCase().includes("huawei") || this.device.manufacturer.toLowerCase().includes("honor")) {
        window.open("https://appgallery1.huawei.com/#/app/C102026649")
      } else {
        window.open("https://play.google.com/store/apps/details?id=leejam.fitnesstime&hl=en", "_system");  
      }
    }
    if (this.platform.is('ios')) {
      // iOS
      window.open("itms-apps://itunes.apple.com/br/app/fitnesstime-app/id1455950113?mt=8", "_system");
    }
    // navigator['app'].exitApp();

    // this.logout.clearData()
    // navigator['app'].exitApp();

  }

  compareVersions(now, latest) {
    if (latest) {
      if (now == latest) {
        return false
      }
      else {
        let a = latest.split(".")
        let b = now.split(".")
        if (a.length == b.length) {
          let len = a.length
          for (let i = 0; i < len; i++) {
            if (a[i] > b[i]) {
              return true;
            }
            else
              if (a[i] < b[i]) {
                return false;
              }

          }

        }
      }
    }
    else {
      return false
    }

  }
}


