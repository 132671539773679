import { async } from '@angular/core/testing';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable,of } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';
import { map,catchError, } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class CartServiceService {

  constructor(private httpclient : HttpClient,
    private HTTPS: HttpService,
    public translateService: TranslateService,
    private storage: Storage,
    private alertCtrl: AlertController,
    private toastController : ToastController
    ) {
      
      this.removeAllGVs();
    }
  private cartSubject = new Subject<CartState>();

  private GVSubject = new Subject<GVStore>();
  private CCSubject = new Subject<CCStore>();

    Products : any[]= [];
    GvArr : any[]= [];
    couponArr: any[] = [];
    Total :any;
    gvAmount = 0;
    gvTax = 0;
    public alertPresnt = false;
    
    CartState = this.cartSubject.asObservable();

    GVState = this.GVSubject.asObservable();
    CCState = this.CCSubject.asObservable();

    
    
  async addProduct(_product:any) {
      console.log('in service');
      this.translateService.get('PREVIOUSOFFERSWILLBEREMOVED').subscribe(
        async addedproduct => {
          this.translateService.get('ALERT').subscribe(
            async alerttranslate => {
          this.translateService.get('ADD').subscribe(
            async add => {
              this.translateService.get('NO').subscribe(
                async no => {
      let alert = await this.alertCtrl.create({
        message: addedproduct,
        subHeader: alerttranslate,
        buttons: [
          {
            text: add,
            handler: () => {
             // this.emptyCart();

            console.log('offer added');
            let offerproducts:any = this.Products.filter(p=>p.type == 'offer');

            console.log(this.Products.filter(p=>p.type == 'offer'))
            offerproducts.forEach(element => {
              this.removeProduct(element);
            });
           
             this.Products.push(_product)

            //this.storage.set('BUYBACK',this.Products)
            this.cartSubject.next(<CartState>{added: true, products:  this.Products});
            }
          },
          {
            text: no,
            handler: () => {
              console.log('offer not added');
              this.alertCtrl.dismiss()
            }
          }
        ],
        backdropDismiss: false
      });

      if(((this.Products.filter(p=>p.type == 'freeze').length) > 0 )  || (((this.Products.filter(p=>p.isdayPass == true).length) > 0) &&  (!_product.isdayPass)  )  )
      {
        this.emptyCart();
        this.Products.push(_product)
        this.cartSubject.next(<CartState>{added: true, products:  this.Products});
      }
      else
      if(((this.Products.filter(p=>p.type == 'offer').length) > 0 ))
      {
       let offerproducts:any = this.Products.filter(p=>p.type == 'offer');
if(_product.type == 'offer'){
  if( ((offerproducts.filter(p=>p.offerDetails.OfferId != _product.offerDetails.OfferId).length) > 0 ) )
        {
            alert.present();
         //this.emptyCart();
        }
        else
        {
          this.Products.push(_product)
          this.cartSubject.next(<CartState>{added: true, products:  this.Products});
        }

}
else
{
  this.Products.push(_product)
  this.cartSubject.next(<CartState>{added: true, products:  this.Products});
}
      
        // if(_product.type != 'offer')
        // {
        //   this.Products.push(_product)
        // }
      }
      else
      {
        this.Products.push(_product)
        this.cartSubject.next(<CartState>{added: true, products:  this.Products});
      }
    });
  });
});
        });
    }

    updateProduct(product)
    {
      for(let i=0;i<this.Products.length;i++)
      {
        if(this.Products[i].ProductId == product.ProductId)
        {
          this.Products[i] = product;

          if(this.Products[i].type == 'offer')
          {
            this.Products[i]['offerDetails']['OfferDocumentsRequired'] = product['offerDetails']['OfferDocumentsRequired']
          }
        }
      }
        this.setAllProducts()
        this.cartSubject.next(<CartState>{added: true, products:  this.Products});
    }
    removeProduct(_prod) {
      let delindx = -1;
      console.log(_prod,'selected prod');
      console.log(this.Products,'cart product');
          this.Products.map((_item,myindx) =>{
            if(_item.ProductId == _prod.ProductId)
            {
              if(_item.type == _prod.type){
                if(_item.type == 'offer' && _prod.type == 'offer')
                {
                  if (_item.offerDetails.OfferId == _prod.offerDetails.OfferId) 
                  {
                    delindx = myindx;
                  }
                }
                else
                {
                  delindx = myindx;
                }
              }
            }
          });
        
      this.Products = this.Products.filter((_item,ind) =>  ind !==  delindx);
      this.cartSubject.next(<CartState>{added: false , products:  this.Products});
      if(this.Products.length == 0)
      {
        this.removeAllGVs();
      }
    }

    refreshCart(){
      console.log(this.Products ,'here')
      this.Products=this.Products ;
      this.cartSubject.next(<CartState>{added: true , products:  this.Products});
    }

    emptyCart()
    {
      this.Products = [];
      this.cartSubject.next(<CartState>{added: false , products:  this.Products});
      this.removeAllGVs();
    }

    emptyCartCP()
    {
      this.Products = [];
      this.removeAllGVs();
      this.storage.set('BUYBACK',[])
      this.cartSubject.next(<CartState>{added: false , products:  this.Products});
     
    }


    ValidateCartProducts(cart): Promise<any>
    {
      
      let changed = false;
      let sendObj = [];
    console.log(sendObj, "sendObjsendObj")

    let promise: Promise<boolean> = new Promise((resolve, reject) => {  
      if ((cart.filter(c=>(c.type != "freeze" && c.type != "gvcode"))).length > 0) {
        this.storage.get('tocken').then((tocken) => {
          cart.map(c => { 
            //     if(c.type !== "freeze" && c.type !== "gvcode")
           //  {
                 let obj = { 'packageid': 0 };
                 if (c.Packageid) {
                     obj['packageid'] = c.Packageid;
                 }
           
                 obj['productid'] = c.ProductId;
           if(c.offerdetails)
           {
             console.log("c.offerdetails",c.offerdetails)
                 if (c.type == 'offer') {
                     if (c.offerdetails.OfferId) {
                         obj['campaignid'] = c.offerdetails.OfferId;
                     }
                     else {
                         obj['campaignid'] = 0;
                     }
           
           
                 }
                 else {
                   obj['campaignid'] = 0;
               }
               }
                 else {
                     obj['campaignid'] = 0;
                 }
           
                 if (c.NetCost) {
                     obj['amount'] = c.NetCost;
                 }
                 if (c.giftMembershipCode) {
                  obj['giftMembershipCode'] =c.giftMembershipCode;
              }
                 console.log(obj, "Singleobj");
                 sendObj.push(obj)
           //  }
             })
  
  
          if(sendObj.length > 0)
          {
            this.HTTPS.CartProductValidity(sendObj,tocken).subscribe(response => {
              let res = response.data;
              console.log("Vslidityy res", response);
              let deleteIndex = [];
              let priceChange = [];
              if (res) {
                res.map(re => {
    
    
                    sendObj.map(so => {
                        if (so.productid == re.productid) {
    
                            if (re['error'].length > 0) {
    
                                if (re['error'] == "amount changed") {
                                    priceChange.push(re)
                                }
                                else
                                {
                                    deleteIndex.push(re)
                                }
    
    
                            }
    
    
    
                        }
                    })
    
    
                });
    
                console.log("priceChangev", priceChange);
                console.log("deleteIndexv", deleteIndex);
                if((deleteIndex.length + priceChange.length) > 0)
                {changed = true}
    
                if (deleteIndex.length > 0) {
                    // removing from cart
    
                            deleteIndex.map((d) => {
                              this.removeProduct(d);
                            });
    
                            this.translateService.get('EXPIREDPRODUCTSINCART').subscribe(
                              EXPIREDPRODUCTSINCART => {
                                    this.translateService.get('SOMEPRODUCTWITHEXPIREDVALIDITYWILLBEREMOVEDFROMCART').subscribe(
                                      Expired => {
                                          this.translateService.get('DISMISS').subscribe(
                                            DISMISS => {
                                              this.presentAlert(EXPIREDPRODUCTSINCART,Expired,DISMISS)
                                              });
                                      });
                                });
                }
    
    
    
                if (priceChange.length > 0) {
                    this.updatePricesinCart(priceChange);
                    this.translateService.get('CHANGEINPRICE').subscribe(
                      CHANGEINPRICE => {
                            this.translateService.get('PRICESOFSOMEPRODUCTSHAVECHANGEDSINCEYOUHAVEADDEDTHEMTOCART').subscribe(
                              PRICESCHANGED => {
                                    this.translateService.get('DISMISS').subscribe(
                                      DISMISS => {
                                            this.presentAlert(CHANGEINPRICE,PRICESCHANGED,DISMISS)
                                        });
                                });
                        });
    
                }
    
                resolve(changed) ;
            } 
            else
            {
              resolve(false) 
            }
            },error=>{
              console.log("errrrrrrrrr",error)
              this.presentToast(error.data.message);
            }
            )
          }
          else
          {
            resolve(false) 
          }
  
        })
      }
      else
      {
        resolve(false);
      }

    });


    return Promise.resolve(promise);
    }


    async presentAlert(h,sub,ok) {
      if(!this.alertPresnt)
      {
        this.alertPresnt = true;
        let alert = await this.alertCtrl.create({
          header: h,
          backdropDismiss:false,
          subHeader: sub,
          buttons: [          {
            text: ok,
            handler: () => {
                this.alertPresnt = false;
            }
          }]
        });
    
        await alert.present();
      }
    }


    updatePricesinCart(priceChangeArray) {
      console.log("updatePricesinCart", priceChangeArray);

              priceChangeArray.map((p,indx) => {
                this.Products.map((_item) => {
                  if(_item.ProductId == p.productid)
                  {
                    let backup = _item;
                    backup.amount = p.amount;
                    backup.NetCost = p.amount;
                    backup.BaseCost = p.amount;

                    this.removeProduct(_item);
                    this.addProduct(backup)
                  }
                }  )

              });
              console.log("After P Update", this.Products)
  }




  getAllProducts()  {
    console.log("getAllProductsSS")
  this.storage.get('BUYBACK').then(val=>{
    if(val)
    {
        if(val.length)
        {
          this.Products=val;
          this.cartSubject.next(<CartState>{added: false , products:  this.Products});
        }
    }
  })
}

setAllProducts()
{ 
  if((this.Products.filter(p=>p.type == 'freeze')).length == 0)
  {
    this.storage.set('BUYBACK',this.Products)
  }
}

// GV ---------------------------------

applyGV(gvAmt,gvTax,voucherNo)
{

  this.GvArr.push(
    {
        "voucherNo":voucherNo,
        "gvAmt":gvAmt,
        "gvTax":gvTax
    }
    )
    this.GVSubject.next(<GVStore>{"added":true,"gvs":this.GvArr,gvPresent:true,gvammt:this.calcGvAmount('amt'),gvtaxtotall:this.calcGvAmount('tax')});
    console.log('gv..................', this.GvArr);
}


// Coupon----------------------------

applyCoupon( cpAmt, tax ,cpNo)
{
this.couponArr.push({
  "couponNo": cpNo,
  "cpAmt": cpAmt,
  "ccTax": tax
})
 this.CCSubject.next(<CCStore>{"added": true, "cpps": this.couponArr, "cpammt": this.calcCCAMT('amt'), cctaxtotall:this.calcCCAMT('tax')})
}
  calcCCAMT(type): number {
    let amt = 0;
    let tax = 0;
    this.couponArr.map(c=>{
      amt = amt + Number(c.cpAmt)
      tax = tax + Number(c.ccTax)
    })
    
   return (type == 'amt') ? amt: tax;
  }


  removeAllCCs()
{
  let promise = new Promise((resolve, reject) => {
    this.couponArr = [];
    this.CCSubject.next(<CCStore>{"added":false,"cpps":[],cpPresent:false,cpammt:0,cctaxtotall:0});
    resolve(true)
  })
  return promise;
 
}


// cc code end

removeAllGVs()
{
  this.GvArr = [];
  this.GVSubject.next(<GVStore>{"added":false,"gvs":[],gvPresent:false,gvammt:0,gvtaxtotall:0});
}


removeGV(code)
{
  this.GvArr = this.GvArr.filter(g=>g.voucherNo != code)
  this.GVSubject.next(<GVStore>{"added":false,"gvs":this.GvArr,gvPresent:((this.GvArr.length) > 0) ? true : false,gvammt:this.calcGvAmount('amt'),gvtaxtotall:this.calcGvAmount('tax')});
}

//  remove coupon code
removeCPC(code) {
this.couponArr = this.couponArr.filter(g => g.couponNo != code);
this.CCSubject.next(<CCStore>{"added": true, "cpps": this.couponArr,cpPresent:((this.couponArr.length > 0)) ,"cpammt": this.calcCCAMT('amt'), cctaxtotall:this.calcCCAMT('tax')});
}


calcGvAmount(type)
{

  let amt = 0;
  let tax = 0;
  this.GvArr.map(g=>{
    amt = amt + Number(g.gvAmt)
    tax = tax + Number(g.gvTax)
  })
  
 return (type == 'amt') ? amt: tax;

}

async presentToast(Messege) {
  const toast = await this.toastController.create({
    message: Messege,
    duration: 2000,
    position: 'bottom'
  });
  toast.present();
}




}

interface CartState {
  added: boolean;
  products : any[];
 
 }

 interface GVStore {
  added: boolean;
  gvs : any[];
  gvPresent:boolean;
  gvammt:number;
  gvtaxtotall:number;
 }

 interface CCStore {
  added: boolean;
  cpps : any[];
  cpPresent:boolean;
  cpammt:number;
  cctaxtotall:number;
 }
 