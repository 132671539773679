import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment'
import { NavController, LoadingController, NavParams, Platform, ModalController } from '@ionic/angular';
import {  NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/services/http/http.service';
import { Storage } from '@ionic/storage';
import { LanguageService } from '../../services/language/language.service';
import { ThemeService } from '../../services/theme/theme.service';
import { Router } from '@angular/router';
import { CompanyId } from '../../config';
@Component({
  selector: 'app-eligible-location-modal',
  templateUrl: './eligible-location-modal.page.html',
  styleUrls: ['./eligible-location-modal.page.scss'],
})
export class EligibleLocationModalPage implements OnInit {
  public locations;
  public themeFlag;
  public memberid;
  public gender;
  public clubArray;
  public loading;
  public tenantNameNull;
  public myNames = [];
  public listtoBind = [];
  public AllCities = [];
  public allSelected = false;
  public myInput;
  themeName;
  @Input() eligibleLocationMebershipIds;
  backup: any[];
  finalList: any[];
  constructor(
    public zone:NgZone,
    public HTTPS: HttpService,
    public navCtrl: NavController,
    public translateService: TranslateService,
    public loadingController: LoadingController,
    private storage: Storage,
    public modalController: ModalController,
    public languageService: LanguageService,
    public navParams: NavParams,
    public theme: ThemeService,
    public platform: Platform,
    public router: Router) {

    // this.presentLoading();

    this.translateService.onLangChange.subscribe((event: any) => {
      console.log(event.lang, "CONSOLING THE VALUE FOR EVENT LANGUAGE")
      // if (event.lang == 'ar') {
      //   platform.setDir('rtl', true);
      //   platform.setDir('ltr', false);
      // }
      // else {
      //   platform.setDir('ltr', true);
      //   platform.setDir('rtl', false);
      // }
    });

    this.storage.get('USER_STORE').then((user1) => {
      this.gender = user1.gender;
      if (String(this.gender).toUpperCase() == "M") {
        this.gender = "MALE"
        this.memberid = user1.MemberID;
      }
      else if (String(this.gender).toUpperCase() == "F") {
        this.gender = "FEMALE";
      }
    });
    if(this.router.url.includes('/redeem-membership')){
      console.log('insideee remeeem')
      let LOC = (this.navParams.get('eligibleLocationMebershipIds'));
      console.log(LOC,'locccccccccccc')
      this.listtoBind = LOC;
      this.finalList = this.listtoBind

    }else
     if(this.router.url.includes('/one-day-pass-info') || this.router.url.includes('/redeem-membership')){      
      let LOC = (this.navParams.get('eligibleLocationMebershipIds'));
      console.log(LOC,'rubaru roshani')
      this.storage.get('tocken').then(tocken=>{
        this.storage.get('langSelect').then(isocode=>{
          let body ={
            lang_IsoCode:isocode,
            centerIds:LOC,
            companyId: CompanyId
          }
          this.HTTPS.ProductsEligibleCenters(tocken,body).subscribe(response =>{
            
            let centers ;
            centers = response['data'].filter(obj => obj['genderrestriction']== this.gender);
            this.listtoBind = centers;
            console.log(centers,'centersss')
          })
        })

      })

    } else {
      let LOC = (this.navParams.get('eligibleLocationMebershipIds'));
      this.locations = LOC.split(',');
      this.cacheInit();


    }
  }

  ionViewWillEnter() {
    this.myInput='';
      this.theme.storedThemetype.then(theme => {
        console.log(theme,"selectedTheme")
        this.themeName = theme
      });
  }

  ngOnInit() {

    setTimeout(() => {
      this.theme.storedThemetype.then(theme => {
        this.themeName = theme;
        console.log(this.themeName)
      });
    }, 2000);
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad EligibleLocationsPage');
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      duration: 5000,
      message: 'Please wait...',
    });
    return this.loading.present();
  }

  cacheInit() {
    this.storage.get("langSelect").then((val) => {
      let ISOCODE = val;
      let cacheval;
      cacheval = String(moment().format("dddd DD MMM YYYY, hh:mm")) + String(this.memberid);
      let cache = {};
      cache['lang_isocode'] = ISOCODE;
      cache['MemberID'] = 1520445723;
      cache['BillMembershipCachebuster'] = cacheval;
      cache['schedulecachebuster'] = cacheval;
      this.storage.get("CACHE_STORE").then((val1) => {
        if (val1) {
          val1['CenterListCache'] = cache;
          this.storage.set('CACHE_STORE', val1);
          this.fetchClubLocator(cache);
        }
        else {
          let CS = [];
          CS['CenterListCache'] = cache;
          this.storage.set('CACHE_STORE', CS);
          this.fetchClubLocator(cache);
        }
      });
    });
  }

  //to fetch json and assign response into array 
  fetchClubLocator(CenterListCache) {
    let clubBody = {
      'companyID': CompanyId
    }
    this.storage.get('langSelect').then((vall) => {
      let IsoCode = vall
      this.HTTPS.withoutTokeninterCenterList(clubBody, IsoCode, CenterListCache)
        .subscribe((response) => {
          this.clubArray = response.data['Tenant'];
          let Names = [];
          for (let i = 0; i < this.locations.length; i++) {
            if (this.returnTName(this.locations[i]) !== -1) {
              Names.push(this.returnTName(this.locations[i]))
            }
          }
          this.myNames = Names;
          this.AllCities = [];
          this.myNames.map(my => {
            if (((this.AllCities.filter(a => a.city == my.TenantAddress[0].city)).length) == 0) {
              this.AllCities.push({ "city": my.TenantAddress[0].city, isSelected: false })
            }
          });
          this.listtoBind = this.myNames;
          this.backup = this.listtoBind 
          this.allSelected = true;
        });
    })
  }

  returnTName(TID) {
    let a = this.clubArray.filter(c => c.TenantId == TID);
    if (a[0]) {
      return a[0];
    }
    else {
      return -1;
    }
  }


  filterAllCity() {
    this.listtoBind = this.myNames;
    this.allSelected = true;
    for (let i = 0; i < this.AllCities.length; i++) {
      this.AllCities[i].isSelected = false;
    }
  }

  filterByCity(city, indx) {
    if (city == 'All') {
      this.listtoBind = this.myNames;
      this.allSelected = true;
      for (let i = 0; i < this.AllCities.length; i++) {
        this.AllCities[i].isSelected = false;
      }
    }
    else {
      this.listtoBind = this.unique(this.myNames.filter(m => m.TenantAddress[0].city == city))
      this.allSelected = false;
      for (let i = 0; i < this.AllCities.length; i++) {
        if (i == indx) {
          this.AllCities[i].isSelected = true;
        }
        else {
          this.AllCities[i].isSelected = false;
        }
      }
    }
  }

  unique(inp) {
    let uniqueValues = new Set(inp);
    let array = Array.from(uniqueValues);
    return array;
  }

  backtoMyMembership() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }


  
  onInput(event) {
    if(event['srcElement']['value']!='')
    {console.log("eventttttt",event['srcElement']['value'])
    console.log("dwvndwv",this.myInput)
    this.search(event['srcElement']['value']);
  }
  else if(event['srcElement']['value']=='')
  {
    this.GetData()
  }
    
  }



  search(key) {
    if (key && key.trim() !== '') {
      this.zone.run(() => {
        console.log("efbefb",key)
      console.log( "wdvmdv",this.filterItems(key,this.listtoBind))
      // this.listtoBind = this.filterItems(key, this.listtoBind).filter(function () { return true; });
      this.listtoBind = this.filterItems(key,this.listtoBind);
      console.log("sdwvkjnwdvwvlmkwv",this.listtoBind)
      });
    }

  }


  filterItems(searchTerm, it) {
    return it.filter((i) => {
      return String(i.TenantName).toLowerCase().indexOf(String(searchTerm).toLowerCase()) > -1 
    });
  }


  GetData(){
  this.listtoBind = this.finalList
  console.log("vfvfevkjfev",this.listtoBind)
  }

  selectedTenant(center) {
    console.log(center,'centerselected')
    const CenterSelectedState = {
      CenterSelectedState: center
    };
    this.modalController.dismiss(CenterSelectedState);

  }

}