export const LoadingTime = 10000;

export const CompanyId = 999;

/*
999 for leejam 
1002 for ff3
*/

export const AppointmentFlag = false;

/*
This is an Environemnt Variable  
*/

export const Apptype = 'leejam'


// ff3 or  leejam 

/*
 * ff3 or  leejam 
 */



export const identityURL = "https://leejamidsrvhotfix.fitnessforce.com";



export const Unisex = false;

/*


false for leejam

true for ff3

*/


export const Onboarding = false;

export const Tocken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjU5QTJDNTlFQTc2REM2NzZDQUEwRTExMzFGOEIwRDgxOEYyMEY5NTYiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJXYUxGbnFkdHhuYktvT0VUSDRzTmdZ'

export const OTPLength = 4;

export const APPversion = "2.1.16";

export const askrating = true;

export const askRatingdays = 30;

export const OnedayPass = true;

export const Giftamembership = true;

export const Reedeemamembership = true;




