import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DataResolverService} from '../app/services/data-resolver/data-resolver.service';
import { AuthGuardService } from './services/authGuard/auth-guard.service';


const routes: Routes = [

 // {path: '**', redirectTo: '/login'},
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule'
  ,canActivate: [AuthGuardService]
 },

  { path: 'center-selection/:id',
  resolve: {
    special: DataResolverService
    } ,canActivate: [AuthGuardService],
     loadChildren: './pages/center-selection/center-selection.module#CenterSelectionPageModule' },
     
//  { path: 'country-modal', loadChildren: './pages/country-modal/country-modal.module#CountryModalPageModule' },
  { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule',canActivate: [AuthGuardService]
},
  { path: 'offer-card-dashboard',canActivate: [AuthGuardService], loadChildren: './pages/offer-card-dashboard/offer-card-dashboard.module#OfferCardDashboardPageModule' },
  { path: 'myprofile',canActivate: [AuthGuardService], loadChildren: './pages/myprofile/myprofile.module#MyprofilePageModule' },
  { path: 'classes',canActivate: [AuthGuardService], loadChildren: './pages/classes/classes.module#ClassesPageModule' },
  { path: 'class-details/:id',canActivate: [AuthGuardService], resolve:{special:DataResolverService}, loadChildren: './pages/class-details/class-details.module#ClassDetailsPageModule' },
  { path: 'feedback-home',canActivate: [AuthGuardService], loadChildren: './pages/feedback-home/feedback-home.module#FeedbackHomePageModule' },
  { path: 'feedback-email/:id',canActivate: [AuthGuardService], resolve:{special:DataResolverService}, loadChildren: './pages/feedback-email/feedback-email.module#FeedbackEmailPageModule' },
  //{ path: 'location-search', loadChildren: './pages/location-search/location-search.module#LocationSearchPageModule' },
  //{ path: 'location-modal', loadChildren: './pages/location-modal/location-modal.module#LocationModalPageModule' },
  { path: 'otp/:id',canActivate: [AuthGuardService],resolve: {special:DataResolverService}, loadChildren: './pages/otp/otp.module#OtpPageModule' },
  { path: 'logoutconfirm',canActivate: [AuthGuardService], loadChildren: './pages/logoutconfirm/logoutconfirm.module#LogoutconfirmPageModule' },
  { path: 'select-brand',canActivate: [AuthGuardService], loadChildren: './pages/select-brand/select-brand.module#SelectBrandPageModule' },
  { path: 'demo',canActivate: [AuthGuardService], loadChildren: './pages/demo/demo.module#DemoPageModule' },
  { path: 'democallback',canActivate: [AuthGuardService], loadChildren: './pages/democallback/democallback.module#DemocallbackPageModule' },
  { path: 'trainer-list',canActivate: [AuthGuardService], loadChildren: './pages/trainer-list/trainer-list.module#TrainerListPageModule' },
  { path: 'trainer-details/:id',canActivate: [AuthGuardService],
  resolve: {
    special: DataResolverService
  },
  loadChildren: './pages/trainer-details/trainer-details.module#TrainerDetailsPageModule' },
  { path: 'club-details/:id',canActivate: [AuthGuardService],
  resolve: {
    special: DataResolverService
    },
     loadChildren: './pages/club-details/club-details.module#ClubDetailsPageModule' },
  { path: 'image-full-view',canActivate: [AuthGuardService], loadChildren: './pages/image-full-view/image-full-view.module#ImageFullViewPageModule' },
  { path: 'photo-viewer',canActivate: [AuthGuardService], loadChildren: './pages/photo-viewer/photo-viewer.module#PhotoViewerPageModule' },
  { path: 'about',canActivate: [AuthGuardService], loadChildren: './pages/about/about.module#AboutPageModule' },

  { path: 'gender-select/:id', canActivate: [AuthGuardService],
  resolve: {
    special: DataResolverService
    },
    loadChildren: './pages/gender-select/gender-select.module#GenderSelectPageModule' },

  { path: 'club-locator/:id',canActivate: [AuthGuardService],
  resolve: {
    special: DataResolverService
    },
     loadChildren: './pages/club-locator/club-locator.module#ClubLocatorPageModule' },

  { path: 'select-language',canActivate: [AuthGuardService], loadChildren: './pages/select-language/select-language.module#SelectLanguagePageModule'},
  { path: 'my-schedule',canActivate: [AuthGuardService], loadChildren: './pages/my-schedule/my-schedule.module#MySchedulePageModule' },
  { path: 'my-membership',canActivate: [AuthGuardService], loadChildren: './pages/my-membership/my-membership.module#MyMembershipPageModule' },
  { path: 'buy-membership/:id',canActivate: [AuthGuardService],
    resolve: {
    special: DataResolverService
    },
    loadChildren: './pages/buy-membership/buy-membership.module#BuyMembershipPageModule' },
  // { path: 'buy-membership-action-modal', loadChildren: './pages/buy-membership-action-modal/buy-membership-action-modal.module#BuyMembershipActionModalPageModule' },
  { path: 'become-member/:id', canActivate: [AuthGuardService],
  resolve: {
    special: DataResolverService
    },
    loadChildren: './pages/become-member/become-member.module#BecomeMemberPageModule' },
      {
    path: 'purchase-list',canActivate: [AuthGuardService],

    loadChildren: './pages/purchase-list/purchase-list.module#PurchaseListPageModule'
  },
  {path: 'call-back-url',canActivate: [AuthGuardService], loadChildren: './pages/call-back-url/call-back-url.module#CallBackUrlPageModule' },
  {path: 'purchase-details/:id',
  resolve: {
    special: DataResolverService
  },
  loadChildren: './pages/purchase-details/purchase-details.module#PurchaseDetailsPageModule'},
  { path: 'buy-new-membership/:id', canActivate: [AuthGuardService],
  resolve: {
    special: DataResolverService
  },
  loadChildren: './pages/buy-new-membership/buy-new-membership.module#BuyNewMembershipPageModule' },
  { path: 'gift-voucher/:id',
  resolve: {
    special: DataResolverService
    },

  loadChildren: './pages/gift-voucher/gift-voucher.module#GiftVoucherPageModule' },    
 //  { path: 'eligible-location-modal', loadChildren: './pages/eligible-location-modal/eligible-location-modal.module#EligibleLocationModalPageModule' },
  //{ path: 'freeze', loadChildren: './pages/freeze/freeze.module#FreezePageModule' },
  { path: 'offer-list', canActivate: [AuthGuardService],loadChildren: './offer-list/offer-list.module#OfferListPageModule' },
  { path: 'cart/:id', canActivate: [AuthGuardService],  resolve: { special: DataResolverService },loadChildren: './cart/cart.module#CartPageModule' },
  { path: 'appointment-modal',canActivate: [AuthGuardService], loadChildren: './appointment-modal/appointment-modal.module#AppointmentModalPageModule' },
  { path: 'appointment-booked/:id',canActivate: [AuthGuardService],  resolve: { special: DataResolverService}, loadChildren: './pages/appointment-booked/appointment-booked.module#AppointmentBookedPageModule' },
  { path: 'appointment-cancel-modal',canActivate: [AuthGuardService], loadChildren: './appointment-cancel-modal/appointment-cancel-modal.module#AppointmentCancelModalPageModule' },
  //{ path: 'search-list', loadChildren: './pages/search-list/search-list.module#SearchListPageModule' },
 // { path: 'offer-terms-modal', loadChildren: './pages/offer-terms-modal/offer-terms-modal.module#OfferTermsModalPageModule' },
 // { path: 'withdraw', loadChildren: './pages/withdraw-modal/withdraw.module#WithdrawPageModule' },
  { path: 'change-language',canActivate: [AuthGuardService], loadChildren: './pages/change-language/change-language.module#ChangeLanguagePageModule' },
  //{ path: 'resume', loadChildren: './pages/resume/resume.module#ResumePageModule' },
  //{ path: 'cancel-freeze', loadChildren: './pages/cancel-freeze/cancel-freeze.module#CancelFreezePageModule' },
 // { path: 'renew-memebership', loadChildren: './pages/renew-memebership/renew-memebership.module#RenewMemebershipPageModule' },
  { path: 'network-error',canActivate: [AuthGuardService], loadChildren: './pages/network-error/network-error.module#NetworkErrorPageModule' },
  { path: 'waiver-terms',canActivate: [AuthGuardService], loadChildren: './pages/waiver-terms/waiver-terms.module#WaiverTermsPageModule' },
  { path: 'dashboard-new',canActivate: [AuthGuardService], loadChildren: './pages/dashboard-new/dashboard-new.module#DashboardNewPageModule' },
  { path: 'profile-update',canActivate: [AuthGuardService], loadChildren: './pages/profile-update/profile-update.module#ProfileUpdatePageModule' },
  { path: 'billing-load/:id',canActivate: [AuthGuardService], loadChildren: './pages/billing-load/billing-load.module#BillingLoadPageModule', 
  resolve: {
    special: DataResolverService
    } },
  { path: 'date-time/:id',canActivate: [AuthGuardService],resolve: {special: DataResolverService}, loadChildren: './pages/date-time/date-time.module#DateTimePageModule' },
  { path: 'specific-trainer/:id' ,canActivate: [AuthGuardService],resolve: {special: DataResolverService},loadChildren: './pages/specific-trainer/specific-trainer.module#SpecificTrainerPageModule' },
  { path: 'in-app-browser/:id',canActivate: [AuthGuardService],resolve: {special: DataResolverService}, loadChildren: './pages/in-app-browser/in-app-browser.module#InAppBrowserPageModule' },
  { path: 'settings',canActivate: [AuthGuardService], loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  { path: 'payment-call-back',canActivate: [AuthGuardService], loadChildren: './payment-call-back/payment-call-back.module#PaymentCallBackPageModule' },
{ path: 'maintenance',canActivate: [AuthGuardService], loadChildren: './pages/maintenance/maintenance.module#MaintenancePageModule' },
  { path : 'class-attendance-popup',canActivate: [AuthGuardService], loadChildren: './pages/class-attendance-popup/class-attendance-popup.module#ClassAttendancePopupPageModule'},
  { path : 'class-rating',canActivate: [AuthGuardService], loadChildren: './pages/class-rating/class-rating.module#ClassRatingPageModule'},
  { path: 'dashboard-ui-mobile', loadChildren: './pages/dashboard-ui-mobile/dashboard-ui-mobile.module#DashboardUiMobilePageModule' },
  { path: 'appointments-select-purpose', loadChildren: './pages/appointments-select-purpose/appointments-select-purpose.module#AppointmentsSelectPurposePageModule' },
  { path: 'gv-summary/:id', loadChildren: './pages/gv-summary/gv-summary.module#GvSummaryPageModule',resolve: {special: DataResolverService} },
  { path: 'map-reminder', loadChildren: './pages/map-reminder/map-reminder.module#MapReminderPageModule' },
  { path: 'gif-splash', loadChildren: './gif-splash/gif-splash.module#GifSplashPageModule' },
  { path: 'one-day-pass/:id', loadChildren: './pages/one-day-pass/one-day-pass.module#OneDayPassPageModule',resolve: {
    special: DataResolverService
    }  },
  { path: 'one-day-pass-info', loadChildren: './one-day-pass-info/one-day-pass-info.module#OneDayPassInfoPageModule',resolve: {
    special: DataResolverService
    }  },
    { path: 'gate-open-close', loadChildren: './pages/gate-open-close/gate-open-close.module#GateOpenClosePageModule' },
  { path: 'redeem', loadChildren: './redeem/redeem.module#RedeemPageModule' },
  { path: 'redeem-membership', loadChildren: './pages/redeem-membership/redeem-membership.module#RedeemMembershipPageModule' },
  { path: 'gift-membership', loadChildren: './pages/gift-membership/gift-membership.module#GiftMembershipPageModule' },
  { path: 'notification-view-modal', loadChildren: './pages/notification-view-modal/notification-view-modal.module#NotificationViewModalPageModule' },
  { path: 'notification-popup', loadChildren: './pages/notification-popup/notification-popup.module#NotificationPopupPageModule' },











  





 // { path: 'offer-detail-modal', loadChildren: './pages/offer-detail-modal/offer-detail-modal.module#OfferDetailModalPageModule' },
  // { path: 'offers', loadChildren: './pages/offers/offers.module#OffersPageModule' },
  // { path: 'buymembership-action-modal', loadChildren: './pages/buymembership-action-modal/buymembership-action-modal.module#BuymembershipActionModalPageModule' },
  // { path: 'offer-terms-modal', loadChildren: './pages/offer-terms-modal/offer-terms-modal.module#OfferTermsModalPageModule' },
  // { path: 'purchase-history', loadChildren: './pages/purchase-history/purchase-history.module#PurchaseHistoryPageModule' },
  // { path: 'transaction-detail', loadChildren: './pages/transaction-detail/transaction-detail.module#TransactionDetailPageModule' },
  //{ path: 'payment-success', loadChildren: './pages/payment-success/payment-success.module#PaymentSuccessPageModule' },
  // { path: 'payment-fail', loadChildren: './pages/payment-fail/payment-fail.module#PaymentFailPageModule' },
  // { path: 'my-membership', loadChildren: './pages/my-membership/my-membership.module#MyMembershipPageModule' },
  // { path: 'resume-modal', loadChildren: './pages/resume-modal/resume-modal.module#ResumeModalPageModule' },
  // { path: 'freeze-membership', loadChildren: './pages/freeze-membership/freeze-membership.module#FreezeMembershipPageModule' },
  // { path: 'eligible-location', loadChildren: './pages/eligible-location/eligible-location.module#EligibleLocationPageModule' },
  // { path: 'generate-gv', loadChildren: './pages/generate-gv/generate-gv.module#GenerateGvPageModule' },
  // { path: 'product-terms-modal', loadChildren: './pages/product-terms-modal/product-terms-modal.module#ProductTermsModalPageModule' },
  // { path: 'cart', loadChildren: './pages/cart/cart.module#CartPageModule' },
  // { path: 'cart-waiver-otp', loadChildren: './pages/cart-waiver-otp/cart-waiver-otp.module#CartWaiverOtpPageModule' },
  // { path: 'cart-profile-info', loadChildren: './pages/cart-profile-info/cart-profile-info.module#CartProfileInfoPageModule' },
  // { path: 'cart-waiver-esign', loadChildren: './pages/cart-waiver-esign/cart-waiver-esign.module#CartWaiverEsignPageModule' },
  // { path: 'cart-waiver-action-modal', loadChildren: './pages/cart-waiver-action-modal/cart-waiver-action-modal.module#CartWaiverActionModalPageModule' },
  // { path: 'cart-waiver-success', loadChildren: './pages/cart-waiver-success/cart-waiver-success.module#CartWaiverSuccessPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
