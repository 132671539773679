import { stringify } from 'querystring';


import { FCM } from '@ionic-native/fcm/ngx';
import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { Platform, Events, ToastController, AlertController, ModalController, MenuController, ActionSheetController, PopoverController } from '@ionic/angular';
import { Location } from '@angular/common';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationExtras, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { HttpService } from './services/http/http.service';
import { ThemeService } from './services/theme/theme.service';
import { NetworkErrorService } from './services/network-error/network-error.service';
import { debounceTime, timestamp } from 'rxjs/operators';
import { LogoutService } from './logout.service'
import { ViewChildren, QueryList } from '@angular/core';
import { IonRouterOutlet } from '@ionic/angular';
import { ChangeLanguagePage } from './pages/change-language/change-language.page';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { DataServiceService } from './services/data-service/data-service.service';
import { Device } from '@ionic-native/device/ngx';
// import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { LanguageService } from './services/language/language.service';
import * as moment from 'moment';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { DashboardUpdateService } from './services/dashboard-update/dashboard-update.service';
import { NetworkErrorPage } from './pages/network-error/network-error.page'
import { Network } from '@ionic-native/network/ngx';
import { GifSplashPage } from "./gif-splash/gif-splash.page"
import { OnedayPass, Giftamembership, Reedeemamembership, Apptype } from './config';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { GateOpenClosePage } from './pages/gate-open-close/gate-open-close.page';
import { UpdateAppService } from './update-app.service';
import { Subscription } from 'rxjs';
import { NotificationViewModalPage } from './pages/notification-view-modal/notification-view-modal.page';




@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent implements OnDestroy { 

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  public clubArray: any = [];
  web: boolean = false;
  
  route = "dashboard";
  public extraMemberArray: any;
  public memberDetailsArray: Object;
  public activeCenter: any;
  public memberName: any;
  public activeStatus: any;
  public profileURL: any;
  public logo: any
  public lastTimeBackPress = 0;
  public timePeriodToExit = 2000;
  public preLoginPagesArray = [];
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  public navigateUrl: string = undefined;
  public oneDayPass = 0;
  public oneDayPasses: number[] = [];
  public ISOCODE;
  public memberships: number[] = [];
  public tocken;
  public gender;
  public tenantID;
  public MemberID;
  

  pages = [
    {
      title: 'Home',
      url: 'dashboard',
      activeUrl: "dashboard",
      icon: 'home.svg',
      web: true
    },
    {
      title: 'My Profile',
      url: './myprofile',
      activeUrl: "myprofile",
      icon: 'use.svg',
      web: true
    },

    {
      title: 'CLASSS',
      url: './classes',
      icon: 'class.svg',
      activeUrl: "classes",
      web: true
    },

    {
      title: 'BUYMEMBERSHIP',
      url: './buy-membership/type',
      activeUrl: "buy-membership/memberDetails",
      activeUrl2: "buy-membership/offerDetails",
      icon: 'buy.svg',
      web: true
    },

    // {
    //  title :' REDEEM ',
    //  url: '/redeem',
    //  icon:'',
    //  web: true
    // },
    //    {
    //     title :'REDEEMAMEMBERSHIP',
    //     url: './redeem-membership',
    //     icon:'buy.svg',
    //     activeUrl:'redeem-membership',
    //     web: true
    //    },

    //  {
    //    title : 'ONEDAYPASS',
    //    url: './one-day-pass-info',
    //    icon: 'onedaypass.svg',
    //    activeUrl:'one-day-pass-info',
    //    web:true
    //  },
    // {
    //   title: 'Gift Voucher',
    //   url: './gift-voucher/0',
    //   icon: 'card',
    //   web : true,
    //   activeUrl : '/gift-voucher/0'
    // },
    //  {
    //   title: 'GIFTAMEMBERSHIP',
    //   url: './gift-voucher/type',
    //   icon: 'gift_a_membership.svg',
    //   activeUrl : '/gift-voucher/type',
    //   web : true
    // },
    {
      title: 'Explore Clubs',
      url: './club-locator/69',
      activeUrl: "club-locator/69",
      icon: 'centers.svg',
      web: false
    },
    {
      title: 'My Schedules',
      url: './my-schedule',
      icon: 'calendar.svg',
      activeUrl: "my-schedule",
      web: true
    },
    {
      title: 'OFFERS',
      url: './offer-list',
      icon: 'offers.svg',
      activeUrl: "offer-list",
      web: true
    },
    {
      title: 'MYMEMBERSHIP',
      url: './my-membership',
      icon: 'My_Membership.svg',
      activeUrl: "my-membership",
      web: false
    },
    {
      title: 'My Purchase History',
      url: './purchase-list',
      icon: 'purchasehistory.svg',
      activeUrl: "purchase-list",
      web: true
    },

    {
      title: 'My Cart',
      url: './cart',
      icon: 'cart.svg',
      activeUrl: "cart",
      web: false
    },
    {
      title: 'Our Trainer',
      url: './trainer-list',
      activeUrl: "trainer-list",
      icon: 'trainer.svg',
      web: true
    },
    {
      title: 'FEEDBACK',
      url: './feedback-home',
      activeUrl: "feedback-home",
      icon: 'feedback.svg',
      web: true
    },

    {
      title: 'About',
      url: './about',
      icon: 'about.svg',
      activeUrl: "about",
      web: false
    },
    {
      title: 'TAB3_TITLE',
      url: './change-language',
      activeUrl: "change-language",
      icon: 'setting.svg',
      web: this.platform.is('android') || this.platform.is('ios') || this.platform.is('mobile')
    },
    {
      title: 'Log Out',
      url: './logoutconfirm',
      icon: 'logout.svg',
      web: true
    }

  ];
  themeName: any;
  profileDetails: any;
  picUrl: any;
  memberStatus: any;
  firstName: any;
  LastName: any;
  ToastMessage: any;
  center: any;
  netModal
  isConnected: boolean = true;
  selectedPage: any;
  centerStatus: any;
  timestamp: string;
  OnedayPassShow: boolean;
  GiftamembershipShow: boolean;
  Reedeemamembership: boolean;
  Apptype: string;
  updateAlertSubscription: Subscription;

  constructor(
    public modalCtrl: ModalController,
    private menu: MenuController,
    private actionSheetCtrl: ActionSheetController,
    public platform: Platform,
    public location: Location,
    public fcm2: FirebaseX,
    private popoverCtrl: PopoverController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public toastCtrl: ToastController,
    private router: Router,
    private translate: TranslateService,
    private http: HttpClient,
    private HTPS: HttpService,
    private storage: Storage,
    public events: Events,
    private updateApp: UpdateAppService,
    public networkService: NetworkErrorService,
    public alertController: AlertController,
    public theme: ThemeService,
    public toast: ToastController,
    private logout: LogoutService,
    public languageService: LanguageService,
    private fcm: FCM,
    public zone: NgZone,
    private screenOrientation: ScreenOrientation,
    private dataService: DataServiceService,
    private devicee: Device,
    private keyboard: Keyboard,
    private _appUpdate: DashboardUpdateService,
    public network: Network,

    // private ga : GoogleAnalytics

  ) 
  { 
    // this.events.publish('updatepopup');
    this.OnedayPassShow = OnedayPass;
    this.GiftamembershipShow = Giftamembership;
    this.Reedeemamembership = Reedeemamembership;


    if (this.Reedeemamembership) {
      let reedeem = {
        title: 'REDEEMAMEMBERSHIP',
        url: './redeem-membership',
        icon: 'noun_Coupon_123779.svg',
        activeUrl: 'redeem-membership',
        web: true
      }
      this.pages.splice(5, 0, reedeem);
    }

    if (this.OnedayPassShow) {
      let onedaypass =
      {
        title: 'ONEDAYPASS',
        url: './one-day-pass-info',
        icon: 'onedaypass.svg',
        activeUrl: 'one-day-pass-info',
        web: true
      }
      this.pages.splice(this.pages[5].title == "REDEEMAMEMBERSHIP" ? 6 : 5, 0, onedaypass);

    }

    this.pages = Array.from(new Set(this.pages));

    events.subscribe('gcmLogout', () => {
      this.logOutviaEvent();
    });


    events.subscribe('OTPloggedin', () => {
      this.checkEnabledFeatures();
    });

    // if(!this.platform.is('cordova'))
    // {
    //   this.storage.get('tenantDetailsForOtp').then(s=>{
    //     console.log("4",s)
    //     this.logo=s.TenantLogo
    //     console.log("5",this.logo)
    //   })
    // }
    // else if(this.platform.is('cordova'))
    // {
    //   // this.events.unsubscribe('brandLogo')
    //   this.events.subscribe('brandLogo', (logo) => {
    //     console.log("1", logo)
    //     this.zone.run(() => {
    //       console.log("2", logo)
    //       this.logo = logo;
    //       console.log("3", this.logo)
    //     })   
    //   });
    // }
    // events.subscribe('brandLogo', (logo) => {
    //   console.log("logo", logo)
    //   this.zone.run(() => {
    //     console.log("logo", logo)
    //     this.logo = logo;
    //     console.log("this.logo", this.logo)
    //   })
    // });


    this.currentUrl = this.router.url;
    this.navigateUrl = this.currentUrl;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
    //geturlClass();

    this.preLoginPagesArray = ['select-language', 'login', 'center-selection/:id', 'otp/:id', 'dashboard']

    //  this.setPortrait();

    this.initializeApp();
    this.profileURL = './assets/img/ClientDefaultImage.svg';

    // this.storage.get('DIR_KEY').then(direction => {
    //   if (direction) {
    //     let lngkey
    //     if (direction == 'ltr') {
    //       lngkey = 'en-US'
    //     }
    //     else {
    //       lngkey = 'ar-AR'
    //     }

    //   }
    //   else {
    //    this.languageService.setLanguage('en-US', 'ltr')
    //   }


    // })




    this.storage.get('LNG_KEY').then(lngkey => {
      if (lngkey) {
        let dir;
        if (lngkey == 'en-US') {
          dir = 'ltr'
          console.log("English", dir)
        } else {
          dir = 'rtl'
          console.log("Arabic", dir)
        }
        //this.languageService.setLanguage(lngkey, dir)
      } else {
        //this.languageService.setLanguage('en-US', 'ltr')
      }
    });

    this.picUrl = ''
    this.firstName = ''
    this.LastName = ''
    this.memberStatus = ''
    this.center = ''


  }

  ngOnInit() {

    //OnedayPass ,Giftamembership,Reedeemamembership

    // this.events.subscribe('brandLogo', (logo) => {
    //   console.log("1", logo)
    //   this.zone.run(() => {
    //     console.log("2", logo)
    //     this.logo = logo;
    //     console.log("3", this.logo)
    //   })   
    // });
    // this.storage.get('tenantDetailsForOtp').then(s=>{
    //   console.log("4",s)
    //   this.logo=s.TenantLogo
    //   console.log("5",this.logo)
    // })

    // if(!this.platform.is('cordova'))
    // {
    //   this.storage.get('tenantDetailsForOtp').then(s=>{
    //     console.log("4",s)
    //     this.logo=s.TenantLogo
    //     console.log("5",this.logo)
    //   })
    // }
    // else if(this.platform.is('cordova'))
    // {
    //   this.events.subscribe('brandLogo', (logo) => {
    //     console.log("1", logo)
    //     this.zone.run(() => {
    //       console.log("2", logo)
    //       this.logo = logo;
    //       console.log("3", this.logo)
    //     })   
    //   });
    // }
    // this.storage.get('USER_STORE').then((user1) => {
    //   if (String(user1.gender).toLowerCase() == 'm') this.gender = "M";
    //   else this.gender = "F";
    //   this.tenantID = user1.tenantID;
    //   this.MemberID = user1.MemberID;
    //   console.log(this.tenantID + ' ' + this.MemberID)
    // });

    // this.storage.get('tocken').then(value => {

    //   this.tocken = value;
    //   console.log('Token  '+ this.tocken);
    //   // this.fetchForProducts(tocken);
    // });    


  }
  geturlClass(url: string) {
    if (this.previousUrl == url) return true;
    else return false;
  }
  public getPreviousUrl() {
    console.log("previousUrlin function", this.previousUrl)

    return this.previousUrl;
  }

  setPortrait() {
    // set to portrait
    this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
  }


  public isDesktop() {
    if (this.platform.is('android')) {
      window.localStorage.setItem('platform', 'android');
    } else if (this.platform.is('ios')) {
      window.localStorage.setItem('platform', 'ios');
    } else {
      this.web = true;
      window.localStorage.setItem('platform', 'web');
      // fallback to browser APIs
    }
  }

  navto(pagename, title) {
    //this.zone.run()p
    if (pagename == './change-language') {
      this.GoToSettings();
    }
    else if (pagename == './one-day-pass-info') {

      this.router.navigate(['/one-day-pass-info'], { skipLocationChange: false, replaceUrl: true });
    }
    else if (pagename == './logoutconfirm') {
      this.openLogoutAlert();
    }
    else if (pagename == './buy-membership/type') {
      const navigateData = {
        'cart': false,
        type: "buy"
      }
      this.dataService.setData('memberDetails', navigateData);
      this.router.navigate(['/buy-membership/memberDetails'], { skipLocationChange: false, replaceUrl: true });

    }
    else if (pagename == './gift-voucher/type') {
      const navigateData = {
        type: 'giftamembership'
      }
      this.dataService.setData('type', navigateData);
      this.router.navigate(['/gift-voucher/type'], { skipLocationChange: false, replaceUrl: true });

    } else if (pagename == './gift-voucher/1') {
      this.storage.remove('giftamemtype');
      this.router.navigate(['/gift-voucher/1'], { skipLocationChange: false, replaceUrl: true });

    }
    else if (pagename == './cart') {
      const navigateData = {
        'cart': true,
      }
      this.dataService.setData('type', navigateData);
      this.router.navigate(['/buy-membership/type'], { skipLocationChange: false, replaceUrl: true });
    }
    else {
      this.router.navigate([pagename])
    }

    this.selectedPage = pagename;

  }

  navtoProfile() {

    this.router.navigate(['./myprofile'], { skipLocationChange: false, replaceUrl: true });
    this.menu.close();

  }





  initializeApp() {
    this.storage.set('isManualUpAlertClosed', true).then(val => console.log(val));
    this.Apptype = Apptype
    //this.translate.use("en-US");

    console.log('Log out members if tenantid is not set');
    this.storage.get('USER_STORE').then((user1) => {
      let tenantId = user1.tenantID;


      this.storage.get('tocken').then(toc => {
if(toc){
      if(!tenantId)
      {
        if(this.platform.is('cordova')){
          this.events.publish("gcmLogout");
        }

        else if(!this.platform.is('cordova'))
        {
          this.logout.clearData()
        }
      }

    }})
    });

    let Val = false

    this.storage.get('tocken').then(value => {
      if (value) {
        Val = true;
      }
    });



    this.storage.get('login').then(value => {
      if (value) {
        if (!Val) {
          //  this.logout.clearData();
        }
      }

    });



    this.platform.ready().then(() => {
      if (this.platform.is('cordova') && this.Apptype == 'leejam') {
        this.platform.resume.subscribe(() => {
          this.storage.get('isManualUpAlertClosed').then(res => {
            if(!res){
              this.updateApp.isAlertOpen.next(true);
            }
          })
        })
        this.presentSplashModal();
        setTimeout(() => {
          
          this.updateAlertSubscription = this.updateApp.isAlertOpen.subscribe(isAlertOpen => {
            if(isAlertOpen) {
              this.updateApp.updateApp();
            }
          })
        }, 4000);

      
      }
      this.backButtonEvent();


      //   this.storage.get("login").then(data=>{
      //     if(data){
      //       this.router.navigate(['/dashboard']);  
      //     }else{
      //       this.router.navigate(['/login']);
      //     }
      // })

      // setTimeout(() => {
      //   this.splashScreen.hide();
      // }, 2500);

      this.languageService.setInitialAppLanguage();

      if (this.platform.is('cordova')) {
        this.setPortrait();
      }

      if (this.platform.is('android') || this.platform.is('ios')) {
        let
          appEl = <HTMLElement>(document.getElementsByTagName('ION-APP')[0]),
          appElHeight = appEl.clientHeight;

        this.keyboard.disableScroll(true);

        window.addEventListener('native.keyboardshow', (e) => {
          appEl.style.height = (appElHeight - (<any>e).keyboardHeight) + 'px';
        });

        window.addEventListener('native.keyboardhide', () => {
          appEl.style.height = '100%';
        });
      }


      //  this.ga.startTrackerWithId('UA-37419551-9')
      //  .then(() => {}).catch(e => alert('Error starting GoogleAnalytics == '+ e));
      setTimeout(() => {
        if (this.network.type == 'none') {
          if (this.platform.is('cordova')) {
            this.presentNWModal()
          }

        }
      }, 2000);


      this.networkSubscriber();
      this.theme.storedThemetype.then(theme => {
        this.themeName = theme;
        this.theme.setTheme(theme);
      });


      this.isDesktop();

      //this.backButtonEvent();

      this.router.events.subscribe((val) => {
        if (this.location.path() != '') {
          this.route = this.location.path();
          this.route = this.route.replace('/', '');

        } else {
        }


        // 'UA-37419551-9'
      });

      // to disable back button for a route

      /*this.platform.backButton.subscribe(() => {
        // this does work
        // console.log("back button clicked")
        // console.log(this.router.url, "active url");
        // if (this.router.url !=null || this.router.url !=undefined && this.router.url) {
        //   this.CloseButton();
        // }


       // this.backButtonEvent()
      });*/

      // this.fcm.onTokenRefresh().subscribe(token => {
      //   console.log("Refreshed Token is : " + token);
      //   let postdata = {
      //     "client_id": "007",
      //     "FcmKey": "" + token
      //   };
      //   this.http.post("http://35.165.61.223:3000/RegisterClient", postdata).subscribe(data => {
      //     //DATA
      //   }, error => {
      //     //ERROR
      //   });
      // });


      // if(this.platform.is('ios')){

      //   this.fcm.getAPNSToken().then(token =>  {

      //     console.log('FCM TOKENN', token)


      //     this.storage.get('tocken').then(v => {
      //       let bo = {
      //         "deviceId": this.devicee.uuid,
      //         "gcmToken": token
      //       }
      //       console.log("bo", bo, token)
      //       this.HTPS.Fcmtokenget(bo, v).then(fcmres => {
      //         console.log(fcmres, "FCMRESPONSE")
      //       }, error => {
      //         console.log(error)
      //       })
      //     })
      //   });

      // }


      if (this.platform.is('ios')) {

        this.fcm2.grantPermission()
          .then((success) => {

            this.fcm2.getToken().then(token => {

              this.storage.get('tocken').then(v => {
                let bo = {
                  "deviceId": this.devicee.uuid,
                  "gcmToken": token
                }
                console.log("bo", bo, token)
                if (v) {
                  this.HTPS.Fcmtokenget(bo, v).then(fcmres => {
                    console.log(fcmres, "FCMRESPONSE")
                  }, error => {
                    console.log(error)
                  })
                }


              })

            });

          });
        this.fcm2.getToken().then(token => {

          this.storage.get('tocken').then(v => {
            let bo = {
              "deviceId": this.devicee.uuid,
              "gcmToken": token
            }
            console.log("bo", bo, token)
            if (v) {
              this.HTPS.Fcmtokenget(bo, v).then(fcmres => {
                console.log(fcmres, "FCMRESPONSE")
              }, error => {
                console.log(error)
              })
            }


          })

        });


      } else {

        this.fcm.getToken().then(token => {
          console.log('FCM TOKENN', token);
          this.storage.get('tocken').then(v => {
            let bo = {
              "deviceId": this.devicee.uuid,
              "gcmToken": token
            }
            console.log("bo", bo, token)
            if (v) {
              this.HTPS.Fcmtokenget(bo, v).then(fcmres => {
                console.log(fcmres, "FCMRESPONSE")
              }, error => {
                console.log(error)
              })

            }

          })

        });


      }


      if (this.platform.is('ios')) {
        this.fcm2.onTokenRefresh().subscribe(token => {
          console.log("Refreshed Token is : " + token);
          let postdata = {
            "client_id": "007",
            "FcmKey": "" + token
          };
          // this.http.post("http://35.165.61.223:3000/RegisterClient", postdata).subscribe(data => {
          //   //DATA
          // }, error => {
          //   //ERROR
          // });
        });

      } else {
        this.fcm.onTokenRefresh().subscribe(token => {
          console.log("Refreshed Token is : " + token);
          let postdata = {
            "client_id": "007",
            "FcmKey": "" + token
          };
          // this.http.post("http://35.165.61.223:3000/RegisterClient", postdata).subscribe(data => {
          //   //DATA
          // }, error => {
          //   //ERROR
          // });
        });

      }




      if (this.platform.is('ios')) {

        this.fcm2.onMessageReceived().subscribe(data => {
          console.log("Data recieved is : ", data);


          // let timestmap = data['google.sent_time'];
          // let checkValid =moment().isAfter(moment(timestmap).add(1,"minutes"),"minutes");
          // if(checkValid) {


          if (data.wasTapped) {
            if (data.payload == "message") {

              // this.events.publish('messegenotification',data);	
              this.zone.run(() => {
                this.ModalForNotification(data)
              })
            }

            let check = data['google.sent_time'];
            console.log(moment(check).add(15, "minute").isAfter(moment()), 'timestamp component');
            let checkValid = moment(check).add(15, "minute").isAfter(moment());
            console.log("Notification received in background");
            if (checkValid) {
              if (data.payload == "AttendancePushNotification" && data.reminder == "false") {
                //this.popflag = true;

                setTimeout(() => {
                  this.router.navigateByUrl('class-attendance-popup');
                  console.log("Attendance data is : ", data);
                  this.storage.set('attendance', data)
                  console.log("Class Attendance function")


                }, 1500);
              }

              else if (data.reminder == "true") {
                //this.popflag = true;
                setTimeout(() => {
                  window.open("http://maps.google.com/?q=" + data.lat + "," + data.long, "_system");

                }, 1500);
              }

              if (data.payload == "appointmentattendance" && data.reminder == "false") {
                //this.popflag = true;
                setTimeout(() => {
                  this.storage.set('rating-data', data)
                  this.router.navigateByUrl('class-rating')
                  console.log("Rating function")
                  console.log("Class rating data from FCM is : ", data.trainer, data.starttime, data.date);
                }, 1500);
              } else {
                this.router.navigate(['/dashboard']);
              }



            }

            else {
              this.noOTPAlert('Notification Expired');
            }

          }

          else                       //FOREGROUND CODE
          {
            console.log("Notification received in foreground");

            if (data.payload == "message") {

              // this.events.publish('messegenotification',data);	
              this.zone.run(() => {
                this.ModalForNotification(data)
              })
            }

            if (data.payload == "AttendancePushNotification" && data.reminder == "false") {
              //this.popflag = true;
              this.storage.set('attendance', data);
              setTimeout(() => {
                this.router.navigateByUrl('class-attendance-popup');
                console.log("Class Attendance function")
                console.log("Attendance data coming from in foreground from FCM is : ", data)
              }, 1500);
            }

            else if (data.reminder == "true") {
              //this.popflag = true;
              // Schedule multiple notifications
              // this.localNotifications.schedule([{
              //   title: data['title'],
              //   text: 'With ' + data['trainer'],
              //   // lockscreen: true,
              //   // priority: 2,
              //   // vibrate : true,
              //   // foreground: true
              // }]);


              if (this.platform.is('ios')) {
                window.open("http://maps.apple.com/?q=" + data.lat + "," + data.long, "_system");
              } else if (this.platform.is('android')) {
                window.open("http://maps.google.com/?q=" + data.lat + "," + data.long, "_system");
              }

              //   this.storage.set('reminder-data', data)
              //   this.router.navigateByUrl('map-reminder')
              //   console.log("Direction function")
              //   console.log("Get direction data is : ", data)
            }
            if (data.payload == "appointmentattendance" && data.reminder == "false") {
              //this.popflag = true;
              setTimeout(() => {
                this.storage.set('rating-data', data)
                this.router.navigateByUrl('class-rating')
                console.log("Rating function")
                console.log("Class rating data from FCM is : ", data.trainer, data.starttime, data.date);

              }, 1500);
            }
            if (data.payload == "onedaypass") {
              console.log("device id", String(this.devicee.uuid))
              console.log("onedaypass")
              if (data.deviceId == String(this.devicee.uuid)) {
                console.log("device id", this.devicee.uuid)

                if (data.entryaccess == "true") {
                  console.log('access = true')
                  setTimeout(() => {
                    const entry = data.entryaccess;

                    console.log("OneDayPass Success");
                    // console.log()


                    this.timestamp = moment().format("YYYY-MM-DD HH:mm ss SSS"),
                      console.log("timestamp ", this.timestamp, data);

                    let dataTimestamp = {

                      timestamp: this.timestamp,
                      notificationsdata: data
                    }

                    this.storage.set('notitimestamp', dataTimestamp).then(() => {
                      console.log("notitimestamp", dataTimestamp)
                    });

                    let getnotitimestamp;
                    this.storage.get('notitimestamp').then(() => {
                      console.log("getnotitimestamp", getnotitimestamp)
                    });
                    this.storage.remove('tenantNameforOnedayPass');
                    this.storage.set('tenantNameforOnedayPass', data.tenantName);
                    this.events.publish('tenantAccess');
                    this.presentGateModal(entry, this.firstName, this.LastName, data.tenantName);
                  }, 300)
                } else {
                  console.log('access = false')
                  setTimeout(() => {
                    // const entry = data.entryaccess;
                    console.log("OneDayPass Fail");
                    this.presentAlertUnsuccessful(data.accessStatusReason);
                    // this.presentGateModal(entry,this.firstName,this.LastName,data.tenantName);

                  }, 300)
                }
              }
            }




          }

          // } else {
          //   this.alertPresent(); 
          // }


        })


      } else {

        this.fcm.onNotification().subscribe(data => {
          console.log("Data recieved is : ", data);




          // let timestmap = data['google.sent_time'];
          // let checkValid =moment().isAfter(moment(timestmap).add(1,"minutes"),"minutes");
          // if(checkValid) {


          if (data.wasTapped) {


            if (data.payload == "message") {
              //this.events.publish('messegenotification',data);
              this.zone.run(() => {
                this.ModalForNotification(data)
              })
            }

            let check = data['google.sent_time'];
            console.log(moment(check).add(15, "minute").isAfter(moment()), 'timestamp component');
            let checkValid = moment(check).add(15, "minute").isAfter(moment());
            console.log("Notification received in background");
            if (checkValid) {
              if (data.payload == "AttendancePushNotification" && data.reminder == "false") {
                //this.popflag = true;

                setTimeout(() => {
                  this.router.navigateByUrl('class-attendance-popup');
                  console.log("Attendance data is : ", data);
                  this.storage.set('attendance', data)
                  console.log("Class Attendance function")


                }, 1500);
              }

              else if (data.reminder == "true") {
                //this.popflag = true;
                setTimeout(() => {
                  window.open("http://maps.google.com/?q=" + data.lat + "," + data.long, "_system");

                }, 1500);
              }

              if (data.payload == "appointmentattendance" && data.reminder == "false") {
                //this.popflag = true;
                setTimeout(() => {
                  this.storage.set('rating-data', data)
                  this.router.navigateByUrl('class-rating')
                  console.log("Rating function")
                  console.log("Class rating data from FCM is : ", data.trainer, data.starttime, data.date);
                }, 1500);
              } else {
                this.router.navigate(['/dashboard']);
              }


            }

            else {
              this.noOTPAlert('Notification Expired');
            }

          }

          else                       //FOREGROUND CODE
          {
            console.log("Notification received in foreground");

            if (data.payload == "message") {

              // this.events.publish('messegenotification',data);	
              this.zone.run(() => {
                this.ModalForNotification(data)
              })
            }

            if (data.payload == "AttendancePushNotification" && data.reminder == "false") {
              this.storage.set('attendance', data);
              //this.popflag = true;
              setTimeout(() => {
                this.router.navigateByUrl('class-attendance-popup');
                console.log("Class Attendance function")
                console.log("Attendance data coming from in foreground from FCM is : ", data)
              }, 1500);
            }

            else if (data.reminder == "true") {
              //this.popflag = true;
              // Schedule multiple notifications

              if (this.platform.is('ios')) {
                window.open("http://maps.apple.com/?q=" + data.lat + "," + data.long, "_system");
              } else if (this.platform.is('android')) {
                window.open("http://maps.google.com/?q=" + data.lat + "," + data.long, "_system");
              }

              //   this.storage.set('reminder-data', data)
              //   this.router.navigateByUrl('map-reminder')
              //   console.log("Direction function")
              //   console.log("Get direction data is : ", data)
            }
            if (data.payload == "appointmentattendance" && data.reminder == "false") {
              //this.popflag = true;
              setTimeout(() => {
                this.storage.set('rating-data', data)
                this.router.navigateByUrl('class-rating')
                console.log("Rating function")
                console.log("Class rating data from FCM is : ", data.trainer, data.starttime, data.date);

              }, 1500);
            }
            if (data.payload == "onedaypass") {
              console.log("device id", String(this.devicee.uuid))
              console.log("onedaypass")
              if (data.deviceId == String(this.devicee.uuid)) {
                console.log("device id", this.devicee.uuid)

                if (data.entryaccess == "true") {
                  console.log('access = true')
                  setTimeout(() => {


                    const entry = data.entryaccess;

                    console.log("OneDayPass Success");
                    // console.log()


                    this.timestamp = moment().format("YYYY-MM-DD HH:mm ss SSS"),
                      console.log("timestamp ", this.timestamp, data);

                    let dataTimestamp = {

                      timestamp: this.timestamp,
                      notificationsdata: data
                    }

                    this.storage.set('notitimestamp', dataTimestamp).then(() => {
                      console.log("notitimestamp", dataTimestamp)
                    });

                    let getnotitimestamp;
                    this.storage.get('notitimestamp').then(() => {
                      console.log("getnotitimestamp", getnotitimestamp)
                    });
                    this.storage.remove('tenantNameforOnedayPass');
                    this.storage.set('tenantNameforOnedayPass', data.tenantName);
                    this.events.publish('tenantAccess');

                    this.presentGateModal(entry, this.firstName, this.LastName, data.tenantName);
                  }, 300)
                } else {
                  console.log('access = false')
                  setTimeout(() => {
                    // const entry = data.entryaccess;
                    console.log("OneDayPass Fail");
                    this.presentAlertUnsuccessful(data.accessStatusReason);

                    // this.presentGateModal(entry,this.firstName,this.LastName,data.tenantName);

                  }, 300)
                }
              }

            }
          }

          // } else {
          //   this.alertPresent(); 
          // }


        })

      }

      this.checkEnabledFeatures()

    });


    //  this.initTranslate();


    this.storage.get('profileData').then(data => {

      if (data) {
        this.zone.run(() => {
          console.log(data, 'pro')
          this.picUrl = data['photoUrl']
          console.log("picurl", this.picUrl)
          this.firstName = data['firstname']
          this.LastName = data['lastname']
          this.memberStatus = data['MemberStatus']
          this.center = data['center']
          this.centerStatus = data['centerStatus']
          this.logo = data['brandLogo']
        })
      }

      this.events.unsubscribe("SidemenuProfile");
      this.events.subscribe('SidemenuProfile', (s) => {
        this.storage.get('profileData').then(data => {
          if (data) {
            this.zone.run(() => {
              this.picUrl = data['photoUrl']
              this.firstName = data['firstname']
              this.LastName = data['lastname']
              this.memberStatus = data['MemberStatus']
              this.center = data['center']
              this.logo = data['brandLogo']


              //         if(this.memberStatus.toLowerCase() != 'enquiry')
              //         {
              //           let odpObj = {

              //             title : 'One Day Pass',
              //             url: './one-day-pass-info',
              //             icon: 'noun_no money_1056231.svg',
              //             web:true
              //           };
              // if((this.pages.filter(p =>p.title ==  'One Day Pass')).length == 0)
              // {
              //   this.pages.splice(5, 0, odpObj);
              //   this.pages = this.pages;
              // }

              //         }


            })
          }

        })
      })

    });
  }

  //  async  alertPresent() {
  //     const alert1 = await this.alertController.create({
  //       header: "Notification Time Expired",
  //       buttons: [
  //       {
  //         text: "ok",
  //         handler: () => {
  //           this.router.navigate(['./dashboard']);
  //         }
  //       }]
  //     });
  //     alert1.present();
  //   }


  initTranslate() {
    this.translate.setDefaultLang('en-US');
    if (this.translate.getBrowserLang() !== undefined) {
      console.log(this.translate.getBrowserLang())
      let lang = 'en-US'
      if (this.translate.getBrowserLang() == 'ar') {
        lang = 'ar-SA'
      }
      this.translate.use(lang);
    } else {
      this.translate.use('en-US');
    }
  }







  //Back Button
  backButtonEvent() {
    // this.router.events.subscribe((url:any) =>{ console.log(url,"meko url de")});
    // console.log(this.router.url == '/login', "active url11");
    // console.log(typeof(this.router.url), "active url112");

    // this._appUpdate.AppUpdateState.subscribe((state) => {
    //   console.log(state, 'inside navi in app component')

    //   let pageState = state.pages



    this.platform.backButton.subscribe(async () => {
      console.log(this.router.url, "active url");
      console.log(this.router.isActive('/home', true), "router.isActive");
      console.log("previousUrl", this.getPreviousUrl())
      console.log(this.router.url == '/login', "active url11");
      console.log(typeof (this.router.url), "active url11");
      try {
        let element = await this.modalCtrl.getTop();
        if (element) {
          if (this.router.url.includes("club-locator")) {
            this.router.navigate(['/club-locator/69']);
            console.log('donothing')
          }
          else
            if ((element.textContent).includes("No Internet")) {
              return;
            }
            else {
              element.dismiss();
              return;
            }


        }
      }
      catch (error) {

      }

      if ((this.router.url.includes('buy-membership') || this.router.url.includes('club-details')) || this.router.url.includes('image-full-view') || this.router.url.includes('photo-viewer') || this.router.url.includes('club-locator') || this.router.url.includes('otp') || this.router.url.includes('specific-trainer')) {
        this.events.publish("cartback");
      }
      else


        if (this.router.url == '/login') {
          console.log('inside login')
          // this.router.navigate(['/login']);
        }
        else {
          console.log("inside 4")
          if (!this.preLoginPagesArray.includes(this.getPreviousUrl())) {
            console.log("inside 5")

            if (this.router.url == '/one-day-pass-info') {
              console.log('inside')
              this.router.navigate(['/dashboard']);
              return
            }

            if (this.router.url == '/redeem-membership') {
              console.log('inside')
              this.router.navigate(['/dashboard']);
              return
            }


            if (this.router.url == "/dashboard" || this.router.url == "/select-language") {
              console.log("inside 6")
              // if (pageState.page == 'dashboard' && pageState.subPage == 'appointmentModal') {
              //   console.log('inside navi 111111111')
              //   let _page = {
              //     page: 'dashboard',
              //     subPage: ''
              //   }
              //   this._appUpdate.addPages(_page);
              //   this.router.navigate(['/dashboard']);
              // } else {
              //   console.log('inside navi 22222222')

              this.presentAlertConfirm();

              // }
            }
            else {
              console.log(this.getPreviousUrl(), 'previoussssss');


              // if( this.currentUrl =='/login' ||this.router.url == "/login" ){

              // }




              if (this.getPreviousUrl() == '/classes') {
                this.router.navigate(['/classes']);
              } else
                if ((this.getPreviousUrl() == '/dashboard')) {
                  this.router.navigate(['/dashboard']);
                } else if (this.getPreviousUrl() == '/my-schedule') {
                  this.router.navigate(['/my-schedule']);
                } else if (this.getPreviousUrl() == '/class-details/classDetails') {
                  this.router.navigate(['/dashboard']);
                } else if (this.getPreviousUrl() == '/class-details/memberDetails') {
                  this.router.navigate(['/dashboard']);
                } else if (this.getPreviousUrl() == '/trainer-list') {
                  this.router.navigate(['/trainer-list']);
                } else if (this.getPreviousUrl() == '/trainer-details/1') {
                  this.router.navigate(['/dashboard']);
                } else if (this.getPreviousUrl() == '/feedback-home') {
                  this.router.navigate(['/feedback-home']);
                } else if (this.getPreviousUrl() == '/feedback-email/memberDetails') {
                  this.router.navigate(['/dashboard']);
                } else if (this.getPreviousUrl() == '/purchase-list') {
                  this.router.navigate(['/purchase-list']);
                } else if (this.getPreviousUrl() == '/purchase-details/1') {
                  this.router.navigate(['/dashboard']);
                } else if (this.getPreviousUrl() == '/date-time/memberDetails' || this.getPreviousUrl() == 'date-time/edit') {
                  console.log('appointmentbooked inside')
                  this.router.navigate(['/dashboard']);
                } else if (this.getPreviousUrl() == '/specific-trainer/memberDetails') {
                  console.log('specific inside')
                  this.router.navigate(['/specific-trainer/memberDetails']);
                }

                // else 
                // if (this.getPreviousUrl() == '/buy-membership/memberDetails' || '/buy-membership/type') {
                //   console.log('billllingggg')
                //   this.router.navigate(['/dashboard']);
                // }
                else {
                  // if (this.router.url.includes('buy-membership')) {
                  //   this.events.publish("cartback")
                  //  }

                }
            }



          }
        }









    });



  }
  async presentAlertConfirm() {
    let closeAppText
    this.translate.get('DOYOUWANTOCLOSETHEAPP').subscribe(value => {
      closeAppText = value;
    })

    let cancelText
    this.translate.get('CANCEL_BUTTON').subscribe(value => {
      cancelText = value;
    })

    let closeText
    this.translate.get('CLOSEAPP').subscribe(value => {
      closeText = value;
    })
    const alert = await this.alertController.create({
      // header: 'Confirm!',
      message: closeAppText,
      buttons: [{
        text: cancelText,
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => { }
      }, {
        text: closeText,
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    });
    await alert.present();
  }
  // active hardware back button


  // active hardware back button

  //      backButtonEvent() {

  //       this.platform.backButton.subscribe(async () => {
  // console.log('LALALALALAL')
  //           // close action sheet

  //           try {

  //               const element = await this.actionSheetCtrl.getTop();
  //               console.log(element,'elementFoundActionSheets');
  //               if (element) {

  //                   element.dismiss();

  //                   return;

  //               }

  //           } catch (error) {

  //           }



  //           // close popover

  //           try {

  //               const element = await this.popoverCtrl.getTop();
  //               console.log(element,'elementFoundPopOver');
  //               if (element) {


  //                   element.dismiss();

  //                   return;

  //               }

  //           } catch (error) {

  //           }



  //           // close modal

  //           try {

  //               const element = await this.modalCtrl.getTop();
  // console.log(element,'elementFoundModal ');
  //               if (element) {

  //                   element.dismiss();

  //                   return;

  //               }

  //           } catch (error) {

  //               console.log(error);



  //           }



  //           // close side menua

  //           try {

  //               const element = await this.menu.getOpen();
  //               console.log(element,'elementFoundMeniu');
  //               if (element !== null) {

  //                   this.menu.close();

  //                   return;



  //               }



  //           } catch (error) {



  //           }



  //           this.routerOutlets.forEach((outlet: IonRouterOutlet) => {


  //             console.log(outlet,'Outlet');
  //             console.log(this.router,'Outlet1');
  //             console.log(this.router.url,'Outlet2');


  //               if (outlet && outlet.canGoBack()) {

  //                   outlet.pop();



  //               } else if (this.router.url === '/home') {


  //                   if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {

  //                       // this.platform.exitApp(); // Exit from app
  //                       navigator['app'].exitApp(); // work for ionic 4

  //                   } else {
  //                       this.CloseButton()
  //                       this.lastTimeBackPress = new Date().getTime();

  //                   }

  //               }

  //           });

  //       });

  //   }




















  toggleMenuState() {
    this.storage.get('profileData').then(data => {

      if (data) {
        this.zone.run(() => {
          console.log(data, 'pro')
          this.picUrl = data['photoUrl']
          this.firstName = data['firstname']
          this.LastName = data['lastname']
          this.memberStatus = data['MemberStatus']
          this.center = data['center']
        })
      }
    });




    console.log('hiiiiiiiiiiiii')
    this.storage.get('USER_STORE').then((valforcenterid) => {
      let centerid = valforcenterid.tenantID;
      this.storage.get('tocken').then((valtocken) => {
        let tocken = valtocken;
        let companyID = '999';
        this.HTPS.withoutTokeninterCenterList(companyID, centerid, {}).subscribe((response) => {
          if (response && response.data && response.data.Tenant) {
            let centers = response.data.Tenant
            if (centers.some(cen => cen['TenantId'] == centerid)) {
              this.HTPS.BillingGVInfo(centerid, tocken).subscribe((response) => {
                console.log(response, 'responseeeee')
                if (response) {
                  if (response.data['isGVEnabled']) {
                    this.zone.run(() => {

                      let gvObj = {

                        title: 'Gift Voucher',
                        url: './gift-voucher/1',
                        icon: 'noun_no money_1056231.svg',
                        web: false
                      };
                      // { title: 'Gift Voucher', component: 'GvcodePage', icon: 'ios-card', color: '#F8F9FD', FontColor: '#000000',customicon:"assets/img/gftblck.svg" }
                      if (this.pages[4].title == "Gift Voucher") {

                      } else {
                        this.pages.splice(4, 0, gvObj);
                        this.pages = this.pages;
                      }
                    })
                  }
                  else if (!response.data['isGVEnabled']) {
                    if (this.pages[4].title == "Gift Voucher") {
                      this.zone.run(() => {
                        this.pages.splice(4, 1);
                      })
                    }
                  }
                }
                this.pages = Array.from(new Set(this.pages));
              })
            }
          }
        })
      })
    })
  }





  async GoToSettings() {

    this.router.navigateByUrl('/settings')
    console.log("Clicked")
    // const modal = await this.modalController.create({
    //   component: ChangeLanguagePage,
    //   cssClass: 'changeLanguage'
    // });
    // modal.present()

  }
  //Direct logout Open

  async openLogoutAlert() {
    let logoutConfirm
    this.translate.get('DOYOUREALLYWANTTOLOGOUTFROMTHEAPPLICATION').subscribe(value => {
      logoutConfirm = value;
    })
    let yes
    this.translate.get('YES').subscribe(value => {
      yes = value;
    })

    let no
    this.translate.get('NO').subscribe(value => {
      no = value;
    })
    const alert = await this.alertController.create({
      message: logoutConfirm,
      buttons: [
        {

          text: no,
          handler: () => {


            console.log('Confirm Cancel');
          }
        }, {
          text: yes,
          handler: () => {
            this.storage.get('tocken').then(toc => {
              this.HTPS.GcmTokenDelete(toc, this.devicee.uuid).subscribe((data) => {
                console.log('Confirm Okay', data);
                if (data.status == 200) {
                  this.logout.clearData()
                }
                else {
                  this.logOUTFailToast()
                }
              }, error => {
                this.logOUTFailToast()
              })

            })

          }
        }
      ]
    });

    await alert.present();
  }

  async noOTPAlert(m) {
    let NotifExpiredText
    this.translate.get('NOTIFICATIONEXPIRED').subscribe(value => {
      NotifExpiredText = value;
    })

    let expiredText
    this.translate.get('EXPIRED').subscribe(value => {
      expiredText = value;
    })
    let alert = await this.alertController.create({
      message: expiredText,
      subHeader: NotifExpiredText,
      buttons: [
        {
          text: expiredText,
          handler: () => {
            this.router.navigate(['./dashboard']);
          }
        }]
    });
    await alert.present();
  }


  //Through  BAck Button Click
  async CloseButton() {
    let closeAppText
    this.translate.get('DOYOUWANTOCLOSETHEAPP').subscribe(value => {
      closeAppText = value;
    })

    let cancelText
    this.translate.get('CANCEL_BUTTON').subscribe(value => {
      cancelText = value;
    })

    let closeAppButtonText
    this.translate.get('CLOSEAPP').subscribe(value => {
      closeAppButtonText = value;
    })
    const alert = await this.alertController.create({
      message: closeAppText,
      buttons: [
        {
          text: cancelText,
          role: 'cancel',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: closeAppButtonText,
          handler: () => {
            navigator['app'].exitApp();
            console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();
  }




  async presentToast() {
    this.translate.get('PRESSAGAINTOEXIT').subscribe(
      value => {
        this.ToastMessage = value;
      }
    )

    let toast = await this.toastCtrl.create({
      message: this.ToastMessage,
      duration: 6000,
      position: 'bottom'
    });
    return toast.present();

  }


  async logOUTFailToast() {
    this.translate.get('LOGOUTNONET').subscribe(
      value => {
        this.ToastMessage = value;
      }
    )

    let toast = await this.toastCtrl.create({
      message: this.ToastMessage,
      duration: 3000,
      position: 'bottom'
    });
    return toast.present();

  }

  networkSubscriber() {
    this.networkService
      .getNetworkStatus()
      .pipe(debounceTime(300))
      .subscribe((connected: boolean) => {
        let isConnected = connected;

        console.log(isConnected, "isConnectedV")
        if (!isConnected) {
          if (this.platform.is('cordova')) {
            this.presentNWModal()
          }




        }

      });
  }





  async openModal() {
    console.log("Clicked")
    const modal = await this.modalCtrl.create({
      component: ChangeLanguagePage,
      cssClass: 'my-custom-modal-css'
    });
  }

  async presentNWModal() {
    const modal = await this.modalCtrl.create({
      component: NetworkErrorPage
    });

    return await modal.present();
  }

  async presentGateModal(access, firstName, LastName, tenantName) {
    const modal = await this.modalCtrl.create({
      component: GateOpenClosePage,
      componentProps: {
        'access': access,
        'firstName': firstName,
        'LastName': LastName,
        'tenantName': tenantName
      }
    });
    return await modal.present();
  }

  async presentSplashModal() {
    const modal = await this.modalCtrl.create({
      component: GifSplashPage
    });
    return await modal.present();
  }

  ngOnDestroy() {
    this.platform.backButton.unsubscribe();
    this.updateAlertSubscription.unsubscribe();
  }

  getSide() {
    return (this.languageService.getSelectedLang() == 'rtl') ? 'end' : 'start';
  }

  isWeb(showOnWeb) {

    if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
      return showOnWeb
    }
    else {
      return true
    }

  }


  logOutviaEvent() {
    if (this.platform.is('cordova')) {
      this.storage.get('tocken').then(toc => {
        this.HTPS.GcmTokenDelete(toc, this.devicee.uuid).subscribe((data) => {
          console.log('Confirm Okay', data);
          if (data.status == 200) {
            this.logout.clearData()
          }
          else {
            this.logOUTFailToast()
          }
        }, error => {
          this.logOUTFailToast()
        })
      })
    }





  }

  async ModalForNotification(data) {
    const modal = await this.modalCtrl.create({
      component: NotificationViewModalPage,
      componentProps: {
        'notificationData': data,
      }
    });
    modal.onDidDismiss().then(val => {
    })
    return await modal.present();
  }

  //maintanainceamodeApi

  async  checkMaintaninacemode() {

    let tenantID;
    let MemberID
    this.storage.get('USER_STORE').then((user1) => {

      tenantID = user1.tenantID;
      MemberID = user1.MemberID;

    });



    this.storage.get('tocken').then(toc => {

      if (toc) {
        let data;
        console.log(tenantID, MemberID);
        // this.HTPS.maintananceMode(toc, this.devicee.uuid).subscribe((data) => {
        if (data == true) {
          this.logOutviaEvent();
        }
        //  });
      }
    })



  }

  async presentAlertUnsuccessful(message) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: message,
      buttons: [
        {
          text: 'OK',
          handler: () => {

            console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();
  }

  checkEnabledFeatures() {
    this.storage.get('tocken').then((tocken) => {
      if (tocken) {
        this.storage.get('USER_STORE').then((US) => {
          this.storage.get('langSelect').then(lang => {

            if (US && US.tenantID && lang) {
              this.HTPS.OtpBillingEnabledFeatures(US.tenantID, tocken, lang).subscribe((response) => {

                if (response) {
                  // if(response.data['isReservationPortalEnabled']) {
                    this.storage.set('isReservationPortalEnabled', response.data['isReservationPortalEnabled']).then(res => {
                      console.log(res);
                    });
                  // }
                  if (response.data['isGiftMembershipEnabled'] && this.GiftamembershipShow) {
                    this.zone.run(() => {

                      let gvMObj = {
                        title: 'GIFTAMEMBERSHIP',
                        url: './gift-voucher/type',
                        icon: 'gift_a_membership.svg',
                        activeUrl: '/gift-voucher/type',
                        web: true
                      };
                      if (this.pages.length > 0 && this.pages.some(x => x.title == "GIFTAMEMBERSHIP")) {

                      } else {
                        this.pages.splice(5, 0, gvMObj);
                        this.pages = this.pages;
                      }
                    })
                  }
                  else if (!response.data['isGiftMembershipEnabled']) {
                    if (this.pages[5].title == "GIFTAMEMBERSHIP") {
                      this.zone.run(() => {
                        this.pages.splice(5, 1);
                      })
                    }
                  }
                  this.pages = Array.from(new Set(this.pages));
                }

                // if(response && response['data'])
                // {
                //   if( true)
                //   {
                //     this.zone.run(() => {
                //        let gvMObj = {
                //             title: 'GIFTAMEMBERSHIP',
                //             url: './gift-voucher/type',
                //             icon: 'gift_a_membership.svg',
                //             activeUrl : '/gift-voucher/type',
                //             web : true
                //           };
                //       if (this.pages[5].title == "GIFTAMEMBERSHIP") {
                //       } else {
                //         this.pages.splice(5, 0, gvMObj);
                //         this.pages = this.pages;
                //       }
                //     })
                //   }

                // }
              })
            }
          })
        })
      }

    })
  }

}

