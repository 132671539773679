import { BrandAndCenterComponent } from './brand-and-center/brand-and-center.component';
import { BrowserModule } from '@angular/platform-browser';
import { ClassbuttonComponent } from './classbutton/classbutton.component';
import { NgModule } from '@angular/core';
import { SkeletonItemComponent } from './skeleton-item/skeleton-item';
import { ShrinkingSegmentHeader } from './shrinking-segment-header/shrinking-segment-header';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {LanguageSwitchComponent }from './language-switch/language-switch.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [SkeletonItemComponent,ShrinkingSegmentHeader,ClassbuttonComponent,BrandAndCenterComponent,LanguageSwitchComponent],
	imports: [IonicModule,CommonModule,FormsModule,TranslateModule],
	exports: [SkeletonItemComponent,ShrinkingSegmentHeader,ClassbuttonComponent,BrandAndCenterComponent,LanguageSwitchComponent]
})
export class ComponentsModule {}
